import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
  AUTH_ERROR
} from "react-admin";
import jwt from "jsonwebtoken";
import { API_URL } from "config";

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(`${API_URL}/login`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" })
    });
    return fetch(request)
      .then(response => {
        return response.json();
      })
      .then(({ token, message }) => {
        if (token) {
          const decodedToken = jwt.decode(token);
          localStorage.setItem("token", token);
          localStorage.setItem("roles", decodedToken.roles);
        }
        if (message) throw new Error(message);
      });
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem("token")
      ? Promise.resolve()
      : Promise.reject({ redirectTo: "/login" });
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem("roles");
    return role === "ROLE_USER" || role === "ROLE_ADMIN"
      ? Promise.resolve(role)
      : Promise.reject();
  }
  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  }
  return Promise.reject("Unknown method");
};
