import React, { useEffect, useState, createRef } from "react";
import { Authenticated, translate } from "react-admin";
import cx from "classnames";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-gb";
import Dashboard from "../views/Dashboard/Dashboard";
import { connect } from "react-redux";
import fetchRoutes from "../actions/UserRoutesAction";
import fetchDates from "../actions/FilterDatesAction";
import fetchCountry from "../actions/FilterCountryAction";
import fetchProject from "../actions/ProjectAction";

import CreateDashboard from "views/Dashboard/CreateDashboard.js";
import UserProfile from "views/Pages/UserProfile.js";
import Projects from "views/Pages/Projects";
import Users from "views/Pages/Users";
import Project from "views/Pages/Project";
import User from "views/Pages/User";
import Upload from "../views/Pages/Upload";
import CustomDashboard from "../views/Dashboard/CustomDashboard";
import Charts from "../views/Pages/Charts";
import Chart from "../views/Pages/Chart";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Person";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PublishIcon from "@material-ui/icons/Publish";
import BarChartIcon from "@material-ui/icons/BarChart";

import _ from "lodash";

const section = "menu";

var ps;

const useStyles = makeStyles(styles);

function Admin(props) {
  const { ...rest } = props;
  const {
    from,
    to,
    country,
    project,
    fetchCountry,
    fetchDates,
    fetchProject,
    fetchRoutes,
    locale
  } = props;
  // states and functions
  let routes = [];
  const role = localStorage.getItem("roles");
  if (role === "ROLE_USER") {
    routes = [
      {
        path: "/dashboard",
        name: props.translate(`${section}.dashboard`),
        rtlName: "لوحة القيادة",
        icon: DashboardIcon,
        component: Dashboard,
        layout: "/admin",
        show: true,
        route: "/dashboard",
        permission: ["ROLE_USER"],
        type: "Dashboard"
      },
      {
        path: "/createdashboard",
        name: props.translate(`${section}.createdashboard`),
        rtlName: "لوحة القيادة",
        icon: AddCircleOutlineIcon,
        component: CreateDashboard,
        layout: "/admin",
        show: true,
        route: "/createdashboard",
        permission: ["ROLE_USER"],
        type: "Page"
      },
      {
        path: "/edituser",
        name: props.translate(`${section}.edituser`),
        rtlName: "لوحة القيادة",
        icon: PersonIcon,
        component: UserProfile,
        layout: "/admin",
        show: false,
        route: "/edituser",
        permission: ["ROLE_ADMIN", "ROLE_USER"],
        type: "Page"
      },
      {
        path: "/editdashboard/:id",
        name: props.translate(`${section}.editdashboard`),
        rtlName: "لوحة القيادة",
        icon: AssignmentIcon,
        component: CreateDashboard,
        layout: "/admin",
        show: false,
        route: "/editdashboards",
        permission: ["ROLE_USER"],
        type: "Page"
      },
      {
        path: "/upload",
        name: props.translate(`${section}.upload`),
        rtlName: "لوحة القيادة",
        icon: PublishIcon,
        component: Upload,
        layout: "/admin",
        show: true,
        route: "/upload",
        permission: ["ROLE_USER"],
        type: "Page"
      },
      {
        path: "/charts",
        name: props.translate(`${section}.charts`),
        rtlName: "لوحة القيادة",
        icon: BarChartIcon,
        component: Charts,
        layout: "/admin",
        show: true,
        route: "/charts",
        permission: ["ROLE_USER"],
        type: "Page"
      },
      {
        path: "/createchart",
        name: props.translate(`${section}.createchart`),
        rtlName: "لوحة القيادة",
        icon: AssignmentIcon,
        component: Chart,
        layout: "/admin",
        show: false,
        route: "/createchart",
        permission: ["ROLE_USER"],
        type: "Page"
      },
      {
        path: "/editchart/:id",
        name: props.translate(`${section}.editchart`),
        rtlName: "لوحة القيادة",
        icon: AssignmentIcon,
        component: Chart,
        layout: "/admin",
        show: false,
        route: "/editchart",
        permission: ["ROLE_USER"],
        type: "Page"
      }
    ];
  } else {
    routes = [
      {
        path: "/projects",
        name: props.translate(`${section}.projects`),
        rtlName: "لوحة القيادة",
        icon: AssignmentIcon,
        component: Projects,
        layout: "/admin",
        show: true,
        route: "/projects",
        permission: ["ROLE_ADMIN"],
        type: "Page"
      },
      {
        path: "/edituser",
        name: props.translate(`${section}.edituser`),
        rtlName: "لوحة القيادة",
        icon: PersonIcon,
        component: UserProfile,
        layout: "/admin",
        show: false,
        route: "/edituser",
        permission: ["ROLE_ADMIN", "ROLE_USER"],
        type: "Page"
      },
      {
        path: "/edituser/:id",
        name: props.translate(`${section}.edituser`),
        rtlName: "لوحة القيادة",
        icon: PersonIcon,
        component: User,
        layout: "/admin",
        show: false,
        route: "/edituser",
        permission: ["ROLE_ADMIN"],
        type: "Page"
      },
      {
        path: "/createproject",
        name: props.translate(`${section}.createproject`),
        rtlName: "لوحة القيادة",
        icon: AssignmentIcon,
        component: Project,
        layout: "/admin",
        show: false,
        route: "/createproject",
        permission: ["ROLE_ADMIN"],
        type: "Page"
      },
      {
        path: "/editproject/:id",
        name: props.translate(`${section}.editproject`),
        rtlName: "لوحة القيادة",
        icon: AssignmentIcon,
        component: Project,
        layout: "/admin",
        show: false,
        route: "/editproject",
        permission: ["ROLE_ADMIN"],
        type: "Page"
      },
      {
        path: "/users",
        name: props.translate(`${section}.users`),
        rtlName: "لوحة القيادة",
        icon: PersonIcon,
        component: Users,
        layout: "/admin",
        show: true,
        route: "/users",
        permission: ["ROLE_ADMIN"],
        type: "Page"
      },
      {
        path: "/createuser",
        name: props.translate(`${section}.createuser`),
        rtlName: "لوحة القيادة",
        icon: PersonIcon,
        component: User,
        layout: "/admin",
        show: false,
        route: "/createuser",
        permission: ["ROLE_ADMIN"],
        type: "Page"
      }
    ];
  }

  if (role === "ROLE_USER") {
    props.routesAPI.forEach(route => {
      let foundObject = _.find(routes, e => {
        return e.name === route.title;
      });

      if (!foundObject) {
        routes.push({
          path: "/custom/:dashboardID",
          name: route.title,
          rtlName: "لوحة القيادة",
          icon: DashboardIcon,
          component: CustomDashboard,
          layout: "/admin",
          show: true,
          route: `/custom/${route.id}`,
          permission: ["ROLE_USER"],
          type: "Dashboard"
        });
      }
    });
  }
  routes.push(
    ...routes.splice(
      routes.findIndex(
        v => v.name === props.translate(`${section}.createdashboard`)
      ),
      1
    )
  );

  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(false);
  const [image] = useState(require("assets/img/sidebar-2.jpg"));
  const [color] = useState("blue");
  const [bgColor] = useState("black");
  const [logo] = useState(require("assets/img/logo-white.svg"));

  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  // ref for main panel div
  const mainPanel = createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount

  useEffect(() => {
    moment().locale(locale);
    const dateFrom = moment().subtract(60, "d");
    const nowDate = moment();
    fetchRoutes();
    fetchDates(dateFrom, nowDate);
    fetchCountry("");
    fetchProject();
  }, [fetchRoutes, fetchCountry, fetchProject, fetchDates, locale]);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].route) !==
          -1
        ) {
          return routes[i];
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes, from, to, country, project) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        if (prop.route === "/dashboard" && role === "ROLE_USER") {
          return (
            <Route
              path="/admin/dashboard"
              component={() => (
                <Dashboard
                  fromDate={from}
                  toDate={to}
                  country={country}
                  currency={project.currency}
                />
              )}
              exact={true}
              key={key}
            />
          );
        } else if (prop.route === "/projects" && role === "ROLE_ADMIN") {
          return (
            <Route path="/admin/projects" component={Projects} exact={true} />
          );
        } else {
          return (
            <Route
              path={`${prop.layout}${prop.path}`}
              component={prop.component}
              key={key}
              exact={true}
            />
          );
        }
      }
      return [];
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  const token = localStorage.getItem("token");

  function handleDateChange(date, dateLabel) {
    let fromDate = from;
    let toDate = to;
    if (dateLabel === "from") fromDate = date;
    else toDate = date;
    fetchDates(fromDate, toDate);
  }
  function handleSelectCountry(country) {
    fetchCountry(country);
  }
  return (
    <Authenticated authParams={{ token }}>
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText={"datalytix.io"}
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          bgColor={bgColor}
          miniActive={miniActive}
          {...rest}
        />
        <div className={mainPanelClasses} ref={mainPanel}>
          <AdminNavbar
            sidebarMinimize={sidebarMinimize.bind(this)}
            miniActive={miniActive}
            brandText={getActiveRoute(routes)}
            handleDrawerToggle={handleDrawerToggle}
            fromDate={from}
            toDate={to}
            handleDateChange={handleDateChange}
            handleSelectCountry={handleSelectCountry}
            country={country}
            countries={project.countries}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>{getRoutes(routes, from, to, country, project)}</Switch>
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <Switch>{getRoutes(routes)}</Switch>
            </div>
          )}
          {getRoute() ? <Footer fluid /> : null}
        </div>
      </div>
    </Authenticated>
  );
}

Admin.propTypes = {
  translate: PropTypes.func,
  routesAPI: PropTypes.array,
  fetchRoutes: PropTypes.func,
  fetchProject: PropTypes.func,
  fetchDates: PropTypes.func,
  fetchCountry: PropTypes.func,
  from: PropTypes.object,
  to: PropTypes.object,
  project: PropTypes.object,
  country: PropTypes.string,
  locale: PropTypes.string
};

const mapStateToProps = state => ({
  routesAPI: state.routes,
  from: state.filterDates.from,
  to: state.filterDates.to,
  country: state.filterCountry,
  project: state.project,
  locale: state.i18n.locale
});

export default connect(
  mapStateToProps,
  { fetchRoutes, fetchDates, fetchCountry, fetchProject }
)(translate(Admin));
