import germanMessages from "ra-language-german";

export default {
  ...germanMessages,
  menu: {
    dashboard: "Dashboard",
    createdashboard: "Dashboard erstellen",
    edituser: "Benutzer bearbeiten",
    projects: "Projekte",
    createproject: "Projekt erstellen",
    editproject: "Projekt bearbeiten",
    users: "Benutzer",
    createuser: "Benutzer erstellen",
    upload: "Upload",
    editdashboard: "Dashboard bearbeiten",
    charts: "Eigene Charts",
    createchart: "Chart erstellen",
    editchart: "Chart bearbeiten"
  },
  users: {
    newuser: "Benutzer hinzufügen",
    users: "Benutzer",
    username: "Username",
    name: "Name",
    mail: "E-Mail",
    role: "Role",
    company: "Firma",
    actions: "Actions"
  },
  general: {
    loading: "Loading",
    cancel: "Löschen",
    submit: "Absenden"
  },
  user: {
    edituser: "Benutzer bearbeiten",
    createuser: "Benutzer erstellen",
    selectproject: "Projekt wählen",
    selectrole: "Role wählen",
    company: "Firma",
    password: "Passwort",
    lastname: "Nachname",
    firstname: "Vorname",
    mail: "E-Mail",
    username: "Username",
    admin: "Admin",
    user: "User"
  },
  projects: {
    createproject: "Projekt erstellen",
    projects: "Projekte",
    title: "Titel",
    nrcountries: "Länderanzahl",
    actions: "Actions"
  },
  project: {
    editproject: "Projekt bearbeiten",
    createproject: "Projekt erstellen",
    title: "Titel",
    selectcountries: "Länder auswählen",
    selectcurrency: "Währung auswählen",
    dbschema: "Datenbank Schema"
  },
  login: {
    login: "Login",
    password: "Passwort",
    forgotpassword: "Passwort vergessen",
    mail: "E-Mail",
    username: "Username"
  },
  dashboardbox: {
    createdashboard: "Dashboard erstellen",
    title: "Titel",
    successmessage: "Dashboard erfolgreich erstellt",
    errormessage: "Es trat ein Fehler auf. Bitte erneut versuchen."
  },
  createdashboard: {
    listcharts: "Chart-Liste"
  },
  draggablechart: {
    name: "Name",
    size: "Größe"
  },
  dashboardcard: {
    name: "Name",
    size: "Größe",
    selectsize: "Größe auswählen"
  },
  navbar: {
    selectcountry: "Land wählen",
    from: "Von",
    to: "Bis",
    editprofile: "Profil bearbeiten",
    logout: "Logout",
    selectlanguage: "Sprache wählen",
    english: "Englisch",
    german: "Deutsch"
  },
  profile: {
    editprofile: "Profil bearbeiten",
    username: "Username",
    email: "E-Mail",
    firstname: "Vorname",
    lastname: "Nachname"
  },
  customdashboard: {
    edit: "Dashboard bearbeiten"
  },
  upload: {
    uploadedfile: "Datei hochladen",
    dragndrop: ".csv Datei hier reinziehen",
    dbschema: "Datenbank auswählen",
    column: "Spalte wählen",
    analyze: "Analysieren",
    error: "Es trat ein Fehler auf. Bitte erneut versuchen",
    acceptedDocs: "Es können nur .csv Dateien verarbeitet werden",
    upload: "Upload",
    headerRow: "Kopf-Spalte",
    delimiter: "Trennzeichen"
  },
  charts: {
    charts: "Eigene Charts",
    title: "Titel",
    createchart: "Chart erstellen"
  },
  chart: {
    editchart: "Chart bearbeiten",
    createchart: "Chart erstellen",
    title: "Titel",
    selecttype: "Chart Typ auswählen",
    query: "Query",
    config: "Konfiguration"
  }
};
