import { call, put, takeLatest } from "redux-saga/effects";
import request from "../api/apiRequests";

const getDashboards = async () => {
  const response = await request("dashboards", "GET");
  return response.data;
};

export default function* onFetchUserRoutes() {
  yield takeLatest("FETCH_ROUTES", function* fetchRoutes() {
    try {
      const routes = yield call(getDashboards);
      yield put({ type: "ROUTES", routes });
    } catch (e) {
      yield put({ type: "FETCH_ROUTES_FAILED", message: e.message });
      return;
    }
  });
}
