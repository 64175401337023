import React, { useRef } from "react";
import { translate } from "react-admin";
import PropTypes from "prop-types";
import { useDrag, useDrop } from "react-dnd";
import { makeStyles } from "@material-ui/core/styles";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
// import Edit from "@material-ui/icons/Edit";
// import SaveIcon from "@material-ui/icons/Save";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import EqualizerIcon from "@material-ui/icons/Equalizer";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import _ from "lodash";

const section = "dashboardcard";

const useStyles = makeStyles(styles);

function DashboardCard({
  translate,
  moveCard,
  index,
  card,
  // sizes,
  removeCard
}) {
  const classes = useStyles();
  // const [editMode, setEditMode] = useState(true);
  // const [selectedSizeName, setSelectedSizeName] = useState("");
  // const [selectedSizeID, setSelectedSizeID] = useState(null);
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: "DASHBOARDCARD",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: "DASHBOARDCARD", id: card.id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  // const changeMode = () => {
  //   if (!editMode) {
  //     card.chartSizes = [`/api/chart-sizes/${selectedSizeID}`];
  //   }
  //   setEditMode(!editMode);
  // };

  // useEffect(() => {
  //   // selectedSizeID
  //   //   ? (card.chartSizes[0] = `/api/chart-size/${selectedSizeID}`)
  //   //   : (card.chartSizes[0] = "/api/chart-size/1");
  //   // setSelectedSizeName(
  //   //   !_.isArray(card.chartSizes) ? card.chartSizes.size.name : "1:1"
  //   // );
  //   // setSelectedSizeID(
  //   //   !_.isArray(card.chartSizes) ? card.chartSizes.size.id : 1
  //   // );
  // }, [card.chartSizes, selectedSizeID]);

  // const handleSelectedSize = event => {
  //   const size = _.find(sizes, { id: event.target.value });
  //   setSelectedSizeName(size.name);
  //   setSelectedSizeID(size.id);
  // };
  return (
    <div ref={ref} style={{ opacity, width: "100%" }}>
      <Card chart className={classes.cardHover}>
        <CardBody style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ minWidth: "20%" }}>
            <h4 className={classes.cardTitle}>
              {translate(`${section}.name`)}:{" "}
              {card.chart ? card.chart.title : card.title}
            </h4>
            {/* {editMode ? ( */}
            {/* <p className={classes.cardCategory}>
              {translate(`${section}.size`)}:{" "}
              {selectedSizeName !== "" ? `[${selectedSizeName}]` : "[1:1]"}
            </p> */}
            {/* ) : ( */}
            {/* <div>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="selected-size"
                  className={classes.selectLabel}
                >
                  {translate(`${section}.selectsize`)}
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={selectedSizeID ? selectedSizeID : ""}
                  onChange={handleSelectedSize}
                  inputProps={{
                    name: "selectedSize",
                    id: "selected-size"
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    {translate(`${section}.selectsize`)}
                  </MenuItem>
                  {sizes.length > 0 &&
                    sizes.map(size => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={size.id}
                        key={size.id}
                      >
                        {size.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div> */}
            {/* )} */}
          </div>
          <div>
            {/* <Button color="transparent" simple justIcon>
              {editMode ? (
                <Edit
                  className={classes.underChartIcons}
                  onClick={changeMode}
                />
              ) : (
                <SaveIcon
                  className={classes.underChartIcons}
                  onClick={changeMode}
                />
              )}
            </Button> */}
            <Button color="transparent" simple justIcon>
              <HighlightOffIcon
                className={classes.underChartIcons}
                onClick={() => removeCard(index, card)}
              />
            </Button>
          </div>
        </CardBody>
        <CardFooter chart>
          <div className={classes.stats}>
            <EqualizerIcon /> {card.type}
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}

DashboardCard.propTypes = {
  translate: PropTypes.func,
  moveCard: PropTypes.func,
  index: PropTypes.number,
  card: PropTypes.object,
  sizes: PropTypes.array,
  removeCard: PropTypes.func,
  dashboardID: PropTypes.string
};

export default translate(DashboardCard);
