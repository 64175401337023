import React, { useMemo, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { API_URL } from "../../config";
import { post } from "axios";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import Snackbars from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import PublishIcon from "@material-ui/icons/Publish";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Clearfix from "components/Clearfix/Clearfix.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import request from "../../api/apiRequests";
import _ from "lodash";
import { translate } from "react-admin";
import PropTypes from "prop-types";

const useStyles = makeStyles(styles);

const useSelectStyle = makeStyles(() => ({
  dropdown: {
    "&:before": {
      borderBottom: "1px solid #D2D2D2"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #D2D2D2"
    },
    "&:after": {
      borderBottom: "2px solid transparent"
    }
  },
  inputLabel: {
    fontSize: 12,
    top: 8,
    textTransform: "uppercase"
  },
  success: {
    border: 0
  },
  error: {
    borderBottom: "1px solid red",
    "&:before": {
      border: "none"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  },
  row: {
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "column"
  }
}));

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  marginBottom: 15,
  height: 200,
  justifyContent: "center",
  overflowY: "scroll"
};

const activeStyle = {
  borderColor: "#2196f3"
};

const section = "upload";
function Upload({ translate }) {
  const classes = useStyles();
  const selectClasses = useSelectStyle();
  const [table, setTable] = useState("");
  const [fileName, setFileName] = useState("");
  let [selectedColumns, setSelectedColumns] = useState([]);
  const [headerRowData, setHeaderRowData] = useState([]);
  const [tableRowData, setTableRowData] = useState([]);
  const [headerRow, setHeaderRow] = useState(0);
  const token = localStorage.getItem("token");
  const [percent, setPercent] = useState(0);
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const [notificationType, setNotificationType] = useState("info");
  const [message, setMessage] = useState("");
  const [delimiter, setDelimiter] = useState(";");
  const [file, setFile] = useState("");
  const [showTableDropDown, setShowTableDropDown] = useState(false);
  const [buttonMode, setButtonMode] = useState(false);
  const onDropAccepted = useCallback(
    acceptedFile => {
      setFile(acceptedFile[0]);
      let data = new FormData();
      data.append("file", acceptedFile[0], acceptedFile[0].name);

      const url = `${API_URL}/csv/upload`;

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`
        },
        onUploadProgress: progressEvent => {
          var percent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (percent >= 100) {
            setPercent(100);
          } else {
            setPercent(percent);
          }
        }
      };
      post(url, data, config).then(response => {
        if (response.data.success) {
          setMessage(response.data.message);
          setFileName(response.data.data.fileName);
          setNotificationType("success");
          setShowTableDropDown(true);
        } else {
          setMessage(translate(`${section}.error`));
          setNotificationType("danger");
        }
        setShowNotificationMessage(true);
        setPercent(0);
        setTimeout(() => close(), 3000);
      });
    },
    [token, translate]
  );

  const analyze = async () => {
    const headerData = {
      fileName,
      headerRow,
      delimiter
    };
    const tableData = {
      schema: "BREUNINGER_SDN",
      table
    };
    const responseHeader = await request("csv/loadHeader", "POST", headerData);
    const responseTable = await request("csv/loadColumns", "POST", tableData);

    const tableHeaders = responseTable.data.data.map(item => {
      return { name: item.COLUMN_NAME, disabled: false };
    });
    const selectedColumns = responseHeader.data.data.map(() => {
      return "";
    });
    setHeaderRowData(responseHeader.data.data);
    setTableRowData(tableHeaders);
    setSelectedColumns(selectedColumns);
    setButtonMode(true);
  };

  const handleSelect = event => {
    setTable(event.target.value);
  };

  const handleDropDownSelect = (event, id, csvColumnName) => {
    const prevIndex = tableRowData.findIndex(
      x => x.name === selectedColumns[id]
    );
    if (prevIndex !== -1) {
      tableRowData[prevIndex] = {
        name: tableRowData[prevIndex].name,
        disabled: false
      };
    }
    selectedColumns[id] = event.target.value;
    setSelectedColumns(selectedColumns);
    const foundIndex = tableRowData.findIndex(
      x => x.name === selectedColumns[id]
    );

    tableRowData[foundIndex] = {
      name: event.target.value,
      disabled: true,
      csvColumnName
    };

    setTableRowData(
      _.uniqBy([...tableRowData, tableRowData[foundIndex]], "name")
    );
  };

  const onDropRejected = useCallback(() => {
    setMessage(translate(`${section}.acceptedDocs`));
    setNotificationType("danger");
    setShowNotificationMessage(true);
    setTimeout(() => close(), 3000);
  }, [translate]);

  const close = () => {
    setMessage("");
    setShowNotificationMessage(false);
    setNotificationType("info");
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ".csv",
    onDropAccepted,
    onDropRejected,
    multiple: false
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {})
    }),
    [isDragActive]
  );
  const handleSubmit = event => {
    event.preventDefault();
    let columns = {};
    tableRowData.forEach(item => {
      columns[item.name] = item.csvColumnName;
    });
    const data = {
      schema: "BREUNINGER_SDN",
      table,
      fileName,
      columns,
      delimiter
    };
    importCSV(data);
  };

  const importCSV = async data => {
    const response = await request("csv/import", "POST", data);
    if (response.data.success) {
      setMessage(response.data.message);
      setNotificationType("success");
    } else {
      setMessage(translate(`${section}.error`));
      setNotificationType("danger");
    }
    setShowNotificationMessage(true);
    setHeaderRowData([]);
    setTableRowData([]);
    setTable("");
    setFileName("");
    setSelectedColumns([]);
    setHeaderRow(0);
    setDelimiter("");
    setFile("");
    setButtonMode(false);
    setTimeout(() => close(), 3000);
  };
  return (
    <div className="container">
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <PublishIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {translate(`${section}.upload`)}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {file ? (
                      <p>
                        {translate(`${section}.uploadedfile`)}: {file.name}
                      </p>
                    ) : (
                      <p>{translate(`${section}.dragndrop`)}</p>
                    )}
                  </div>
                  <CustomLinearProgress
                    variant="determinate"
                    color="primary"
                    value={percent}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  {showTableDropDown && (
                    <FormControl fullWidth required>
                      <InputLabel
                        htmlFor="table"
                        className={selectClasses.inputLabel}
                      >
                        {translate(`${section}.dbschema`)}
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        className={selectClasses.dropdown}
                        value={table}
                        onChange={handleSelect}
                        inputProps={{
                          name: "table",
                          id: "table"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          {translate(`${section}.dbschema`)}
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple
                          }}
                          value="TV_PLAN"
                        >
                          TV
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple
                          }}
                          value="MARKETING_ANALYSIS1"
                        >
                          Marketing
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  {table && (
                    <div>
                      <CustomInput
                        labelText={translate(`${section}.headerRow`)}
                        id="headerrow"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: event => {
                            setHeaderRow(event.target.value);
                          },
                          defaultValue: headerRow ? headerRow : 0,
                          type: "number"
                        }}
                      />
                      <CustomInput
                        labelText={translate(`${section}.delimiter`)}
                        id="delimiter"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: event => {
                            setDelimiter(event.target.value);
                          },
                          defaultValue: delimiter,
                          type: "text"
                        }}
                      />
                    </div>
                  )}
                </GridItem>
                <GridItem xs={12}>
                  {headerRowData.length > 0 &&
                    headerRowData.map((item, index) => {
                      return (
                        <GridContainer key={index}>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            className={selectClasses.row}
                          >
                            <p>{item}</p>
                          </GridItem>
                          {tableRowData.length > 0 && (
                            <GridItem xs={12} sm={12} md={6}>
                              <FormControl fullWidth>
                                <InputLabel
                                  htmlFor={`column-${index}`}
                                  className={selectClasses.inputLabel}
                                >
                                  {translate(`${section}.column`)}
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu
                                  }}
                                  classes={{
                                    select: classes.select
                                  }}
                                  className={selectClasses.dropdown}
                                  value={
                                    selectedColumns[index]
                                      ? selectedColumns[index]
                                      : ""
                                  }
                                  onChange={e =>
                                    handleDropDownSelect(e, index, item)
                                  }
                                  inputProps={{
                                    name: "column",
                                    id: `column-${index}`
                                  }}
                                >
                                  <MenuItem
                                    disabled
                                    classes={{
                                      root: classes.selectMenuItem
                                    }}
                                  >
                                    {translate(`${section}.column`)}
                                  </MenuItem>
                                  {tableRowData.map((row, j) => {
                                    return (
                                      <MenuItem
                                        disabled={row.disabled}
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelectedMultiple
                                        }}
                                        value={row.name}
                                        key={j}
                                      >
                                        {row.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </GridItem>
                          )}
                        </GridContainer>
                      );
                    })}
                </GridItem>
                <Button
                  color="info"
                  className={classes.updateProfileButton}
                  onClick={buttonMode ? handleSubmit : analyze}
                  disabled={table !== "" ? false : true}
                >
                  {buttonMode
                    ? translate(`general.submit`)
                    : translate(`${section}.analyze`)}
                </Button>
                <Clearfix />
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbars
        place="tl"
        color={notificationType}
        icon={AddAlert}
        message={message}
        open={showNotificationMessage}
        closeNotification={() => setShowNotificationMessage(false)}
      />
    </div>
  );
}

Upload.propTypes = {
  translate: PropTypes.func
};

export default translate(Upload);
