import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ChartIndicator from "./ChartIndicator";

const DrawColumnLineChart = data => {
  const configuration = JSON.parse(data.config);
  am4core.useTheme(am4themes_animated);
  am4core.ready(() => {
    let chart = am4core.create(data.id.toString(), am4charts.XYChart);
    // Create map instance
    chart.numberFormatter.numberFormat = "#,###.##";
    chart.width = "100%";

    // Export
    // chart.exporting.menu = new am4core.ExportMenu();

    chart.data = data.data;

    /* Create axes */
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "x";
    categoryAxis.renderer.minGridDistance = 100;

    let label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.maxWidth = 120;
    label.tooltipText = "{category}";

    categoryAxis.tooltip.label.maxWidth = 120;
    categoryAxis.tooltip.label.wrap = true;

    let tooltipX = configuration.x === "DAYPART" ? "" : "in {categoryX}";

    /* Create value axis */
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    /* Create series */
    let columnSeries = chart.series.push(new am4charts.ColumnSeries());
    columnSeries.name = `${configuration.y1}`;
    columnSeries.dataFields.valueY = "y1";
    columnSeries.dataFields.categoryX = "x";

    columnSeries.columns.template.tooltipText = `[#fff font-size: 15px]{name} ${tooltipX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]`;
    columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
    columnSeries.columns.template.propertyFields.stroke = "stroke";
    columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
    columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
    columnSeries.tooltip.label.textAlign = "middle";
    columnSeries.yAxis = valueAxis;

    if (configuration.y2) {
      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.name = `${configuration.y2}`;
      lineSeries.dataFields.valueY = "y2";
      lineSeries.dataFields.categoryX = "x";

      lineSeries.stroke = am4core.color("#fdd400");
      lineSeries.strokeWidth = 3;
      lineSeries.propertyFields.strokeDasharray = "lineDash";
      lineSeries.tooltip.label.textAlign = "middle";
      lineSeries.yAxis.numberFormatter.numberFormat = "##,###.#";

      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#fdd400"); // tooltips grab fill from parent by default
      bullet.tooltipText = `[#fff font-size: 15px]{name} ${tooltipX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]`;
      let circle = bullet.createChild(am4core.Circle);
      circle.radius = 4;
      circle.fill = am4core.color("#fff");
      circle.strokeWidth = 3;
    }
    if (configuration.y3) {
      let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      // valueAxis1.title.text = "CPV";
      valueAxis1.renderer.grid.template.disabled = true;
      valueAxis1.renderer.opposite = true;

      /* Create series */
      let columnSeries1 = chart.series.push(new am4charts.ColumnSeries());
      columnSeries1.name = `${configuration.y3}`;
      columnSeries1.dataFields.valueY = "y3";
      columnSeries1.dataFields.categoryX = "x";
      columnSeries1.columns.template.tooltipText = `[#fff font-size: 15px]{name} ${tooltipX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]`;
      columnSeries1.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries1.columns.template.propertyFields.stroke = "stroke";
      columnSeries1.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries1.columns.template.propertyFields.strokeDasharray =
        "columnDash";
      columnSeries1.tooltip.label.textAlign = "middle";
      columnSeries1.yAxis = valueAxis1;
    }
    if (configuration.y4) {
      let lineSeries1 = chart.series.push(new am4charts.LineSeries());
      lineSeries1.name = `${configuration.y4}`;
      lineSeries1.dataFields.valueY = "y4";
      lineSeries1.dataFields.categoryX = "x";

      lineSeries1.stroke = am4core.color("#fdd400");
      lineSeries1.strokeWidth = 3;
      lineSeries1.propertyFields.strokeDasharray = "lineDash";
      lineSeries1.tooltip.label.textAlign = "middle";
      lineSeries1.yAxis.numberFormatter.numberFormat = "##,###.#";

      let bullet1 = lineSeries1.bullets.push(new am4charts.Bullet());
      bullet1.fill = am4core.color("#fdd400"); // tooltips grab fill from parent by default
      bullet1.tooltipText = `[#fff font-size: 15px]{name} ${tooltipX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]`;
      let circle1 = bullet1.createChild(am4core.Circle);
      circle1.radius = 4;
      circle1.fill = am4core.color("#fff");
      circle1.strokeWidth = 3;
    }
    ChartIndicator(chart);
  });
};

export default DrawColumnLineChart;
