/* eslint-disable no-undef */
import React, { forwardRef, useState, useEffect } from "react";
import { userLogin, translate } from "react-admin";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

import { connect } from "react-redux";
import Snackbars from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import request from "../../api/apiRequests";

const useStyles = makeStyles(styles);

const section = "login";

// eslint-disable-next-line react/display-name
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const mapStateToProps = state => {
  return {
    showNotification: state.admin.notifications
  };
};

function LoginPage({ userLogin, translate, showNotification }) {
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [modal, setModal] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("info");
  const [emailAddress, setEmailAddress] = useState("");

  setTimeout(() => {
    setCardAnimation("");
  }, 700);

  const classes = useStyles();

  const handleSubmit = event => {
    event.preventDefault();
    userLogin({ username, password });
  };

  const handleKeyPress = event => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      userLogin({ username, password });
    }
  };

  useEffect(() => {
    if (showNotification.length > 0) {
      setAlertType(showNotification[0].type);
      setMessage(showNotification[0].message);
      setShowNotificationMessage(true);
      setTimeout(closeModal, 3000);
    }
  }, [showNotification]);

  const closeModal = () => {
    setModal(false);
    setMessage("");
    setShowNotificationMessage(false);
    setAlertType("info");
    setEmailAddress("");
  };

  const forgotPassword = async () => {
    const response = await request("forgot-password", "POST", {
      mail: emailAddress
    });
    setAlertType(response.data.success ? "success" : "danger");
    setMessage(response.data.message);
    setShowNotificationMessage(true);
    setTimeout(closeModal, 3000);
  };

  const handleChange = event => {
    if (event.target.id === "emailAddress") setEmailAddress(event.target.value);
    else setCredentials({ [event.target.id]: event.target.value });
  };

  return (
    <div className={classes.container}>
      <Snackbars
        place="tl"
        color={alertType}
        icon={AddAlert}
        message={message}
        open={showNotificationMessage}
        closeNotification={() => setShowNotificationMessage(false)}
        close
      />
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onKeyPress={handleKeyPress}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="info"
              >
                <h4 className={classes.cardTitle}>
                  {translate(`${section}.login`)}
                </h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText={translate(`login.username`)}
                  id="username"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email />
                      </InputAdornment>
                    ),
                    onChange: event => {
                      setUsername(event.target.value);
                    }
                  }}
                />
                <CustomInput
                  labelText={translate(`${section}.password`)}
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon>lock_outline</Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    onChange: event => {
                      setPassword(event.target.value);
                    }
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  simple
                  color="github"
                  size="lg"
                  onClick={() => setModal(true)}
                >
                  {translate(`${section}.forgotpassword`)}
                </Button>
                <Button
                  color="info"
                  simple
                  size="lg"
                  block
                  onClick={handleSubmit}
                >
                  {translate(`${section}.login`)}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
      <Dialog
        open={modal}
        transition={Transition}
        keepMounted
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogActions>
          <form>
            <Card login>
              <CardBody>
                <CustomInput
                  labelText={translate(`${section}.mail`)}
                  id="emailAddress"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email />
                      </InputAdornment>
                    ),
                    onChange: handleChange,
                    value: emailAddress
                  }}
                />
              </CardBody>
              <CardFooter>
                <Button onClick={() => setModal(false)}>
                  {translate(`general.cancel`)}
                </Button>
                <Button onClick={forgotPassword} color="success">
                  {translate(`general.submit`)}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </DialogActions>
      </Dialog>
    </div>
  );
}

LoginPage.propTypes = {
  userLogin: PropTypes.func,
  translate: PropTypes.func,
  showNotification: PropTypes.array
};

export default connect(
  mapStateToProps,
  { userLogin }
)(translate(LoginPage));
