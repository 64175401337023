import React, { useEffect, useState } from "react";
import { translate } from "react-admin";
import PropTypes from "prop-types";
import request from "../../api/apiRequests";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const section = "projects";

const useStyles = makeStyles(styles);

function Projects({ translate, history }) {
  const classes = useStyles();
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const goToUserProject = id => {
      history.push(`/admin/editproject/${id}`);
    };
    const simpleButtons = projectID => {
      return [{ color: "success", icon: Edit }].map((prop, key) => {
        return (
          <Button
            color={prop.color}
            simple
            className={classes.actionButton}
            key={key}
            onClick={() => goToUserProject(projectID)}
          >
            <prop.icon className={classes.icon} />
          </Button>
        );
      });
    };
    const getProjects = async () => {
      const response = await request("projects", "GET");
      const projects = response.data.map((project, i) => [
        i + 1,
        project.title,
        project.countries.length,
        simpleButtons(project.id, i)
      ]);
      setProjects(projects);
      setIsLoading(false);
    };
    getProjects();
  }, [classes.actionButton, classes.icon, history]);
  const newProject = () => {
    history.push("/admin/createproject");
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button color="info" onClick={newProject}>
          {translate(`${section}.createproject`)}
        </Button>
      </div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {translate(`${section}.projects`)}
              </h4>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress
                    style={{ color: "#00acc1", width: 50, height: 50 }}
                  />
                  <p style={{ color: "#000" }}>
                    {translate(`general.loading`) + "..."}
                  </p>
                </div>
              ) : (
                <Table
                  tableHead={[
                    "#",
                    translate(`${section}.title`),
                    translate(`${section}.nrcountries`),
                    ""
                  ]}
                  tableData={projects}
                  customCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right
                  ]}
                  customClassesForCells={[0, 4, 5]}
                  customHeadCellClasses={[
                    classes.center,
                    classes.right,
                    classes.right
                  ]}
                  customHeadClassesForCells={[0, 4, 5]}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

Projects.propTypes = {
  history: PropTypes.object,
  translate: PropTypes.func
};

export default translate(Projects);
