import React, { useEffect, useState } from "react";
import { translate } from "react-admin";
import PropTypes from "prop-types";
import jwt from "jsonwebtoken";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Snackbars from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import request from "../../api/apiRequests";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(styles);

const section = "profile";

function UserProfile({ translate }) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const [notificationType, setNotificationType] = useState("info");
  const [apiMessage, setAPIMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userID, setUserID] = useState(null);

  const getProfile = async id => {
    const response = await request(`users/${id}`, "GET");
    const { username, mail, firstName, lastName } = response.data;
    setEmail(mail);
    setFirstName(firstName);
    setLastName(lastName);
    setUsername(username);
    setIsLoading(false);
  };

  const close = () => {
    setAPIMessage("");
    setShowNotificationMessage(false);
    setNotificationType("info");
  };

  const updateProfile = async (id, profile) => {
    const response = await request(`users/${id}`, "PUT", profile);
    setAPIMessage(response.message);
    setShowNotificationMessage(true);
    setNotificationType(response.type ? "success" : "danger");
    setTimeout(() => close(), 3000);
  };

  useEffect(() => {
    const TOKEN = localStorage.getItem("token");
    const decodedToken = jwt.decode(TOKEN);
    setUserID(decodedToken.id);
    setIsLoading(true);
    getProfile(decodedToken.id);
  }, []);

  const handleSubmit = event => {
    event.preventDefault();
    const profile = {
      mail: email,
      firstName,
      lastName
    };
    updateProfile(userID, profile);
  };

  return (
    <GridContainer>
      <Snackbars
        place="tl"
        color={notificationType}
        icon={AddAlert}
        message={apiMessage}
        open={showNotificationMessage}
        closeNotification={() => setShowNotificationMessage(false)}
      />
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <PermIdentity />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {translate(`${section}.editprofile`)}
            </h4>
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  style={{ color: "#00acc1", width: 50, height: 50 }}
                />
                <p style={{ color: "#000" }}>
                  {translate(`general.loading`) + "..."}
                </p>
              </div>
            ) : (
              <div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      disabled
                      id="username"
                      label={translate(`${section}.username`)}
                      value={username ? username : ""}
                      variant="filled"
                      style={{ marginTop: 4 }}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={translate(`${section}.email`)}
                      id="email-address"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setEmail(event.target.value);
                        },
                        value: email ? email : "",
                        type: "email"
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={translate(`${section}.firstname`)}
                      id="firstName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setFirstName(event.target.value);
                        },
                        value: firstName ? firstName : ""
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={translate(`${section}.lastname`)}
                      id="last-name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setLastName(event.target.value);
                        },
                        value: lastName ? lastName : ""
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </div>
            )}
            <Button
              color="info"
              className={classes.updateProfileButton}
              onClick={handleSubmit}
            >
              {translate(`general.submit`)}
            </Button>
            <Clearfix />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

UserProfile.propTypes = {
  translate: PropTypes.func
};

export default translate(UserProfile);
