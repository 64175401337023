import React from "react";
import { translate } from "react-admin";
import { useDrag } from "react-dnd";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import CardFooter from "components/Card/CardFooter.js";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import FiberNewIcon from "@material-ui/icons/FiberNew";

const section = "draggablechart";

const useStyles = makeStyles(styles);

function DraggableChart({ card, type, translate }) {
  const classes = useStyles();
  const [{ opacity }, drag] = useDrag({
    item: { card, type },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  return (
    <div ref={drag} style={{ opacity }}>
      <Card>
        <CardBody style={{ display: "flex", justifyContent: "space-between" }}>
          <h4 className={classes.cardTitle}>
            {translate(`${section}.name`)}: {card.title}
          </h4>
          {card.predefined === 0 && <FiberNewIcon />}
        </CardBody>
        <CardFooter chart>
          <div className={classes.stats}>
            <EqualizerIcon /> {card.type}
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}

DraggableChart.propTypes = {
  card: PropTypes.object,
  type: PropTypes.string,
  translate: PropTypes.func
};

export default translate(DraggableChart);
