import { API_URL } from "../config";
import jwt from "jsonwebtoken";

const locale = localStorage.getItem("locale");

function getToken() {
  const token = localStorage.getItem("token");
  const decodedToken = jwt.decode(token);
  const dateNow = new Date();

  if (decodedToken !== null && decodedToken.exp * 1000 < dateNow.getTime()) {
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    return null;
  }
  return token;
}

async function request(url, method, body) {
  const token = getToken();
  let headers = {
    "Content-Type": "application/json",
    "Accept-Language": locale
  };
  if (url !== "forgot-password") {
    headers.Authorization = `Bearer ${token}`;
  }
  const requestHeaders = {
    method,
    headers: new Headers(headers)
  };
  if (method !== "GET" && method !== "DELETE")
    requestHeaders.body = JSON.stringify(body);
  const request = new Request(`${API_URL}/${url}`, requestHeaders);
  const response = await fetch(request);
  if (method !== "DELETE") {
    const data = await response.json();
    let result = {};
    if (data["hydra:description"]) {
      result.message = data["hydra:description"];
      result.type = false;
    } else {
      if (method !== "GET" && method !== "DELETE") {
        result.message = data["@type"]
          ? `${data["@type"]} updated successfully`
          : data.message;
        result.type = true;
      }
      result.data = data["hydra:member"] ? data["hydra:member"] : data;
    }
    return result;
  }
}

export default request;
