import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { translate } from "react-admin";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import DashboardBox from "views/Dashboard/DashboardBox";
import request from "../../api/apiRequests";
import DraggableChart from "views/Charts/DraggableChart";
import update from "immutability-helper";
import _ from "lodash";

const section = "createdashboard";

function CreateDashboard({ history, translate, match }) {
  const [charts, setCharts] = useState([]);
  const [title, setTitle] = useState("");
  const [dashboardCharts, setDashboardCharts] = useState([]);
  const [removedCharts, setRemovedCharts] = useState([]);
  const [submitFlag, setSubmitFlag] = useState(false);
  const getDashboard = useCallback(
    async (id, charts) => {
      const response = await request(`dashboards/${id}`, "GET");
      setTitle(response.data.title);
      setDashboardCharts(response.data.chartSizes);
      if (match.params.id) {
        const responseCharts = response.data.chartSizes.map(chart => {
          return chart.chart;
        });
        setCharts(_.differenceBy(charts, responseCharts, "title"));
      }
      setRemovedCharts([]);
    },
    [match.params.id]
  );
  const getCharts = useCallback(async () => {
    const response = await request("charts", "GET");
    setCharts(response.data);
    if (match.params.id) getDashboard(match.params.id, response.data);
    setSubmitFlag(false);
  }, [match.params.id, getDashboard]);

  useEffect(() => {
    getCharts();
  }, [getCharts]);

  const handleDrop = useCallback(
    (index, item) => {
      let { card } = item;
      card.new = true;
      setDashboardCharts(
        update(dashboardCharts, card ? { $push: [card] } : { $push: [] })
      );
      if (card !== undefined) {
        let filteredArray = charts.filter(chart => chart.title !== card.title);
        setCharts(filteredArray);
      }
    },
    [dashboardCharts, charts]
  );

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = dashboardCharts[dragIndex];
      setDashboardCharts(
        update(dashboardCharts, {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]]
        })
      );
    },
    [dashboardCharts]
  );

  const removeCard = useCallback(
    (index, card) => {
      setDashboardCharts(update(dashboardCharts, { $splice: [[index, 1]] }));
      if (match.params.id) card.chartSizes = ["/api/chart-size/17"];
      setCharts(update(charts, { $push: [card.chart] }));
      setRemovedCharts(update(removedCharts, { $push: [card] }));
    },
    [dashboardCharts, charts, match.params.id]
  );

  const submitFlagHandler = useCallback(
    submitFlag => {
      if (submitFlag) {
        getCharts();
      }
      setSubmitFlag(submitFlag);
    },
    [submitFlag]
  );
  return (
    <DndProvider backend={HTML5Backend}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <DashboardBox
            allowedDropEffect={"any"}
            dashboardCharts={dashboardCharts}
            onDrop={item => handleDrop(1, item)}
            moveCard={moveCard}
            removeCard={removeCard}
            history={history}
            title={title}
            dashboardID={match.params.id}
            submitFlagHandler={submitFlagHandler}
            submitFlag={submitFlag}
            removedCharts={removedCharts}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <h4>{translate(`${section}.listcharts`)}</h4>
          <div
            style={{
              height: 720,
              overflow: "hidden",
              overflowY: "scroll"
            }}
          >
            {charts.length > 0 &&
              charts.map((card, i) => (
                <DraggableChart
                  key={card.id}
                  index={i}
                  card={card}
                  type="CARD"
                />
              ))}
          </div>
        </GridItem>
      </GridContainer>
    </DndProvider>
  );
}

CreateDashboard.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  translate: PropTypes.func
};

export default translate(CreateDashboard);
