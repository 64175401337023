import React, { useState } from "react";
import { connect } from "react-redux";
import { Select, MenuItem } from "@material-ui/core/";
import { changeLocale, translate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useSelectStyle = makeStyles(() => ({
  underline: {
    fontSize: 12,
    "&:before": {
      borderBottom: "1px solid #d2d2d2"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #d2d2d2"
    },
    "&:after": {
      borderBottom: "2px solid #9c27b0"
    }
  }
}));

function LocaleSwitcher({ translate, changeLocale }) {
  const [language, setLanguage] = useState("de");
  const handleChange = event => {
    changeLocale(event.target.value);
    setLanguage(event.target.value);
    localStorage.setItem("locale", event.target.value);
  };
  const selectClasses = useSelectStyle();
  return (
    <Select
      onChange={handleChange}
      id="select"
      value={language}
      className={selectClasses.underline}
    >
      <MenuItem value="" disabled style={{ fontSize: 12 }}>
        {translate(`navbar.selectlanguage`)}
      </MenuItem>
      <MenuItem value={"de"} style={{ fontSize: 12 }}>
        {translate(`navbar.german`)}
      </MenuItem>
      <MenuItem value={"en"} style={{ fontSize: 12 }}>
        {translate(`navbar.english`)}
      </MenuItem>
    </Select>
  );
}

LocaleSwitcher.propTypes = {
  changeLocale: PropTypes.func,
  translate: PropTypes.func
};

export default connect(
  undefined,
  { changeLocale }
)(translate(LocaleSwitcher));
