import React from "react";
import { fetchUtils, Admin, translate } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import createAdminStore from "./createAdminStore";
import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import { API_URL, TOKEN } from "config";
import { Provider } from "react-redux";
import { createHashHistory } from "history";
import AdminLayout from "./layouts/Admin";
import LoginPage from "./views/Pages/LoginPage";
import { Router, Switch, Redirect } from "react-router-dom";
import authProvider from "./authProvider";
import userNavigationSaga from "./sagas/UserRoutesSaga";
import userRoutesReducer from "./reducers/UserRoutesReducer";
import englishMessages from "./translations/en";
import germanMessages from "./translations/de";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  options.headers.set("Authorization", `Bearer ${TOKEN}`);
  return fetchUtils.fetchJson(url, options);
};
const dataProvider = simpleRestProvider(API_URL, httpClient);

const history = createHashHistory();

const messages = {
  de: germanMessages,
  en: englishMessages
};
const i18nProvider = locale => messages[locale];

localStorage.setItem("locale", "de");

const App = () => (
  <Provider
    store={createAdminStore({
      authProvider,
      dataProvider,
      history,
      i18nProvider,
      locale: "de"
    })}
  >
    <Router history={history}>
      <Switch>
        <Admin
          authProvider={authProvider}
          history={history}
          appLayout={AdminLayout}
          loginPage={LoginPage}
          customReducers={{ navigation: userRoutesReducer }}
          customSagas={[userNavigationSaga]}
        >
          {permissions => [
            (permissions === "ROLE_USER" && (
              <Redirect exact from="/" to="/admin/dashboard" />
            )) ||
              (permissions === "ROLE_ADMIN" && (
                <Redirect exact from="/" to="/admin/projects" />
              ))
          ]}
        </Admin>
      </Switch>
    </Router>
  </Provider>
);

export default translate(App);
