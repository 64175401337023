import { call, put, takeLatest } from "redux-saga/effects";
import request from "../api/apiRequests";

const getProject = async () => {
  const response = await request("projects/1", "GET");
  return response.data;
};

export default function* onFetchProject() {
  yield takeLatest("FETCH_PROJECT", function* fetchProject() {
    try {
      const project = yield call(getProject);
      const countries = project.countries;
      const currency = project.currency;
      const payload = { countries, currency };
      yield put({ type: "PROJECT", payload });
    } catch (e) {
      yield put({ type: "FETCH_PROJECT_FAILED", message: e.message });
      return;
    }
  });
}
