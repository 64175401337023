import React from "react";
import { translate } from "react-admin";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "moment/locale/de";
import "moment/locale/en-gb";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import Person from "@material-ui/icons/Person";

// core components
import Button from "components/CustomButtons/Button.js";
import LogoutButton from "../../components/LogoutButton";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import GridItem from "components/Grid/GridItem.js";
import { NavLink } from "react-router-dom";
import Select from "@material-ui/core/Select";
import LocaleSwitcher from "../../localeSwitcher";

const section = "navbar";

const useStyles = makeStyles(styles);

const useSelectStyle = makeStyles(() => ({
  underline: {
    fontSize: 12,
    "&:before": {
      borderBottom: "1px solid #d2d2d2"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #d2d2d2"
    },
    "&:after": {
      borderBottom: "2px solid #9c27b0"
    }
  }
}));

function HeaderLinks({
  rtlActive,
  handleDateChange,
  routeType,
  translate,
  countries,
  handleSelectCountry,
  country,
  from,
  to,
  locale
}) {
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const classes = useStyles();
  const selectClasses = useSelectStyle();
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });
  // eslint-disable-next-line no-unused-vars
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true
  });
  const validFromDate = current => {
    if (to !== undefined) return current.isBefore(to.format("YYYY-MM-DD"));
  };
  const validToDate = current => {
    if (from !== undefined && to !== undefined) {
      return (
        current.isBefore(to.format("YYYY-MM-DD")) &&
        current.isSameOrAfter(from.format("YYYY-MM-DD"))
      );
    }
  };
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {routeType === "Dashboard" &&
        localStorage.getItem("roles") === "ROLE_USER" && (
          <GridItem xs={12} sm={6} md={10} style={{ display: "flex" }}>
            <GridItem xs={12} sm={6} md={4}>
              <FormControl
                fullWidth
                style={{
                  height: 35,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end"
                }}
              >
                <Select
                  value={country}
                  onChange={e => handleSelectCountry(e.target.value)}
                  displayEmpty
                  className={selectClasses.underline}
                >
                  <MenuItem value="" style={{ fontSize: 12 }}>
                    {translate(`${section}.selectcountry`)}
                  </MenuItem>
                  {countries !== undefined &&
                    countries.map(country => {
                      return (
                        <MenuItem
                          key={country.id}
                          value={country.nameShortEn}
                          style={{ fontSize: 12 }}
                        >
                          {country.nameShortEn}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <Datetime
                  timeFormat={false}
                  dateFormat={true}
                  locale={locale}
                  inputProps={{
                    placeholder: translate(`${section}.from`)
                  }}
                  value={from}
                  closeOnSelect={true}
                  isValidDate={validFromDate}
                  onChange={e => handleDateChange(e, "from")}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <Datetime
                  timeFormat={false}
                  dateFormat={true}
                  locale={locale}
                  inputProps={{ placeholder: translate(`${section}.to`) }}
                  value={to}
                  closeOnSelect={true}
                  isValidDate={validToDate}
                  onChange={e => handleDateChange(e, "to")}
                />
              </FormControl>
            </GridItem>
          </GridItem>
        )}
      <GridItem xs={12} sm={6} md={2}>
        <FormControl
          fullWidth
          style={{
            height: 35,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end"
          }}
        >
          <LocaleSwitcher />
        </FormControl>
      </GridItem>
      <GridItem xs={12} sm={6} md={1}>
        <div className={managerClasses}>
          <Button
            color="transparent"
            aria-label="Person"
            justIcon
            aria-owns={openProfile ? "profile-menu-list" : null}
            aria-haspopup="true"
            onClick={handleClickProfile}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ""
            }}
            style={{ margin: 0, padding: 0 }}
          >
            <Person
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            <Hidden mdUp implementation="css">
              <span onClick={handleClickProfile} className={classes.linkText}>
                {rtlActive ? "الملف الشخصي" : "Profile"}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={Boolean(openProfile)}
            anchorEl={openProfile}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openProfile,
              [classes.popperResponsive]: true,
              [classes.popperNav]: true
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={handleCloseProfile}>
                    <MenuList role="menu">
                      <NavLink
                        className={dropdownItem}
                        to={"/admin/edituser"}
                        onClick={handleCloseProfile}
                      >
                        {translate(`${section}.editprofile`)}
                      </NavLink>
                      <Divider light />
                      <LogoutButton />
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </GridItem>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
  from: PropTypes.object,
  to: PropTypes.object,
  handleDateChange: PropTypes.func,
  routeType: PropTypes.string,
  translate: PropTypes.func,
  countries: PropTypes.array,
  handleSelectCountry: PropTypes.func,
  country: PropTypes.string,
  locale: PropTypes.string
};

const mapStateToProps = state => ({
  from: state.filterDates.from,
  to: state.filterDates.to,
  locale: state.i18n.locale
});

export default connect(mapStateToProps)(translate(HeaderLinks));
