import React, { useState, useEffect } from "react";

// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Chart from "../../components/Charts/DrawChart";
import Box from "../../components/Charts/BoxValues";

import request from "../../api/apiRequests";

export default function Dashboard() {
  const [charts, setCharts] = useState([]);
  const getDashboard = async id => {
    const response = await request(`dashboards/${id}`, "GET");
    setCharts(response.data.chartSizes);
  };

  useEffect(() => {
    getDashboard(1);
  }, []);
  return (
    charts.length > 0 && (
      <GridContainer spacing={4}>
        <GridItem xs={12} sm={12} md={12} style={{ paddingBottom: 0 }}>
          <GridContainer spacing={1}>
            <GridItem xs={12} sm={4} md={2}>
              <Box
                id={charts[1] ? charts[1].id : 2}
                height="150px"
                fixedDecimalScale={true}
                dashboard={"1"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={2}>
              <Box
                id={charts[2] ? charts[2].id : 3}
                height="150px"
                fixedDecimalScale={true}
                dashboard={"1"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={2}>
              <Box
                id={charts[6] ? charts[6].id : 7}
                height="150px"
                fixedDecimalScale={true}
                dashboard={"1"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={2}>
              <Box
                id={charts[3] ? charts[3].id : 4}
                height="150px"
                fixedDecimalScale={true}
                dashboard={"1"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={2}>
              <Box
                id={charts[4] ? charts[4].id : 5}
                height="150px"
                fixedDecimalScale={true}
                dashboard={"1"}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={2}>
              <Box
                id={charts[5] ? charts[5].id : 6}
                height="150px"
                fixedDecimalScale={true}
                dashboard={"1"}
              />
            </GridItem>
          </GridContainer>
          <GridContainer spacing={1}>
            <GridItem xs={12} sm={12} md={4}>
              <GridContainer spacing={4}>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ paddingTop: 0, paddingBottom: 10 }}
                >
                  <Chart
                    id={charts[8] ? charts[8].id.toString() : 9}
                    height={200}
                    dashboard={"1"}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ paddingTop: 0, paddingBottom: 10 }}
                >
                  <Chart
                    id={charts[7] ? charts[7].id.toString() : 8}
                    height={200}
                    dashboard={"1"}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <GridContainer spacing={4}>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ paddingTop: 0, paddingBottom: 10 }}
                >
                  <Chart
                    id={charts[11] ? charts[11].id.toString() : 12}
                    height={200}
                    dashboard={"1"}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ paddingTop: 0, paddingBottom: 10 }}
                >
                  <Chart
                    id={charts[12] ? charts[12].id.toString() : 13}
                    height={200}
                    dashboard={"1"}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Chart
                id={charts[15] ? charts[15].id.toString() : 16}
                height={410}
                dashboard={"1"}
              />
            </GridItem>
          </GridContainer>
          <GridContainer spacing={1}>
            <GridItem xs={12} sm={12} md={4}>
              <GridContainer spacing={4}>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ paddingTop: 0, paddingBottom: 10 }}
                >
                  <Chart
                    id={charts[9] ? charts[9].id.toString() : 10}
                    height={200}
                    dashboard={"1"}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ paddingTop: 0, paddingBottom: 10 }}
                >
                  <Chart
                    id={charts[10] ? charts[10].id.toString() : 11}
                    height={200}
                    dashboard={"1"}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <GridContainer spacing={4}>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ paddingTop: 0, paddingBottom: 10 }}
                >
                  <Chart
                    id={charts[13] ? charts[13].id.toString() : 14}
                    height={200}
                    dashboard={"1"}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ paddingTop: 0, paddingBottom: 10 }}
                >
                  <Chart
                    id={charts[14] ? charts[14].id.toString() : 15}
                    height={200}
                    dashboard={"1"}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <GridContainer spacing={4}>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ paddingTop: 0, paddingBottom: 10 }}
                >
                  <Chart
                    id={charts[16] ? charts[16].id.toString() : 17}
                    height={200}
                    dashboard={"1"}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={6}
                  style={{ paddingTop: 0, paddingRight: 5 }}
                >
                  <Chart
                    id={charts[17] ? charts[17].id.toString() : 18}
                    height={200}
                    dashboard={"1"}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={6}
                  style={{ paddingTop: 0, paddingLeft: 5 }}
                >
                  <Chart
                    id={charts[18] ? charts[18].id.toString() : 19}
                    height={200}
                    dashboard={"1"}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    )
  );
}
