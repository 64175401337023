import * as am4core from "@amcharts/amcharts4/core";

const ChartIndicator = chart => {
  var indicator;
  function showIndicator() {
    if (indicator) {
      indicator.show();
    } else {
      indicator = chart.tooltipContainer.createChild(am4core.Container);
      indicator.background.fill = am4core.color("#fff");
      indicator.background.fillOpacity = 0.8;
      indicator.width = am4core.percent(100);
      indicator.height = am4core.percent(100);

      var indicatorLabel = indicator.createChild(am4core.Label);
      indicatorLabel.text = "No Data Found";
      indicatorLabel.align = "center";
      indicatorLabel.valign = "middle";
      indicatorLabel.fontSize = 20;
    }
  }

  function hideIndicator() {
    indicator.hide();
  }

  chart.events.on("beforedatavalidated", function(ev) {
    if (ev.target.data.length === 0) {
      showIndicator();
    } else if (indicator) {
      hideIndicator();
    }
  });
};

export default ChartIndicator;
