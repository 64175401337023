import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ChartIndicator from "./ChartIndicator";

const DrawParetoChart = data => {
  const configuration = JSON.parse(data.config);
  am4core.useTheme(am4themes_animated);
  am4core.ready(() => {
    let chart = am4core.create(data.id.toString(), am4charts.XYChart);
    chart.numberFormatter.numberFormat = "#.#";
    chart.width = "100%";
    chart.scrollbarX = new am4core.Scrollbar();

    let tooltipX = configuration.x === "DAYPART" ? "" : "in {categoryX}";

    // Add data
    chart.data = data.data;
    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "x";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 100;

    let label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.maxWidth = 120;
    label.tooltipText = "{category}";

    categoryAxis.tooltip.label.maxWidth = 120;
    categoryAxis.tooltip.label.wrap = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;
    valueAxis.min = 0;
    valueAxis.cursorTooltipEnabled = false;

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.name = `${configuration.y1}`;
    series.dataFields.valueY = "y1";
    series.dataFields.categoryX = "x";
    series.columns.template.tooltipText = `[#fff font-size: 15px]{name} ${tooltipX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]`;
    series.columns.template.strokeWidth = 0;
    series.yAxis.numberFormatter.numberFormat = "#.#";

    series.tooltip.pointerOrientation = "vertical";

    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;

    // on hover, make corner radiuses bigger
    let hoverState = series.columns.template.column.states.create("hover");
    hoverState.properties.cornerRadiusTopLeft = 0;
    hoverState.properties.cornerRadiusTopRight = 0;
    hoverState.properties.fillOpacity = 1;

    series.columns.template.adapter.add("fill", (fill, target) => {
      return chart.colors.getIndex(target.dataItem.index);
    });

    let paretoValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    paretoValueAxis.renderer.opposite = true;
    paretoValueAxis.renderer.grid.template.disabled = true;
    paretoValueAxis.cursorTooltipEnabled = false;

    if (configuration.y2) {
      let paretoSeries = chart.series.push(new am4charts.LineSeries());
      paretoSeries.dataFields.valueY = "y2";
      paretoSeries.dataFields.categoryX = "x";
      paretoSeries.yAxis = paretoValueAxis;
      paretoSeries.name = `${configuration.y2}`;
      paretoSeries.tooltipText = `[#fff font-size: 15px]{name} ${tooltipX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]`;
      paretoSeries.bullets.push(new am4charts.CircleBullet());
      paretoSeries.strokeWidth = 2;
      paretoSeries.stroke = new am4core.InterfaceColorSet().getFor(
        "alternativeBackground"
      );
      paretoSeries.strokeOpacity = 0.5;
      paretoSeries.yAxis.numberFormatter.numberFormat = "#.#";
    }
    if (configuration.y3) {
      let paretoSeries1 = chart.series.push(new am4charts.LineSeries());
      paretoSeries1.dataFields.valueY = "y3";
      paretoSeries1.dataFields.categoryX = "x";
      paretoSeries1.yAxis = paretoValueAxis;
      paretoSeries1.name = `${configuration.y3}`;
      paretoSeries1.tooltipText = `[#fff font-size: 15px]{name} ${tooltipX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]`;
      paretoSeries1.bullets.push(new am4charts.CircleBullet());
      paretoSeries1.strokeWidth = 2;
      paretoSeries1.stroke = new am4core.InterfaceColorSet().getFor(
        "alternativeBackground"
      );
      paretoSeries1.strokeOpacity = 0.5;
      paretoSeries1.yAxis.numberFormatter.numberFormat = "#.#";
    }
    if (configuration.y4) {
      // Create series
      let series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.sequencedInterpolation = true;
      series1.dataFields.valueY = "y4";
      series1.dataFields.categoryX = "x";
      series1.name = `${configuration.y4}`;
      series1.columns.template.tooltipText = `[#fff font-size: 15px]{name} ${tooltipX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]`;
      series1.columns.template.strokeWidth = 0;
      series1.yAxis.numberFormatter.numberFormat = "#.#";

      series1.tooltip.pointerOrientation = "vertical";

      series1.columns.template.column.cornerRadiusTopLeft = 10;
      series1.columns.template.column.cornerRadiusTopRight = 10;
      series1.columns.template.column.fillOpacity = 0.8;
    }

    // Cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "panX";
    ChartIndicator(chart);
  });
};

export default DrawParetoChart;
