import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { translate } from "react-admin";
import "../../../node_modules/react-resizable/css/styles.css";
import "../../../node_modules/react-grid-layout/css/styles.css";
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";
import request from "../../api/apiRequests";
import Box from "../../components/Charts/BoxValues";
import Chart from "../../components/Charts/DrawChart";
import Button from "components/CustomButtons/Button.js";
import { connect } from "react-redux";

const ReactGridLayout = WidthProvider(RGL);

const section = "customdashboard";

function CustomDashboard({ match, history, translate }) {
  const [charts, setCharts] = useState([]);
  const [layout, setLayout] = useState([]);
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setCharts([]);
    setFirstRender(true);
    const getDashboard = async id => {
      const response = await request(`dashboards/${id}`, "GET");
      setCharts(response.data.chartSizes);
      generateLayout(response.data.chartSizes);
    };
    getDashboard(match.params.dashboardID);
  }, [match.params.dashboardID]);

  function onLayoutChange(layout) {
    if (!firstRender) {
      setLayout(layout);
      layout.forEach(async (chart, index) => {
        const response = await request(
          `sizes?w=${layout[index].w}&h=${layout[index].h}`,
          "GET"
        );
        const chartSize = {
          size: response.data[0]["@id"],
          dashboard: `/api/dashboards/${match.params.dashboardID}`,
          chart: `/api/charts/${charts[index].chart.id}`,
          x: layout[index].x,
          y: layout[index].y,
          id: charts[index].id
        };
        await request(`chart-sizes/${charts[index].id}`, "PUT", chartSize);
      });
    } else {
      setFirstRender(false);
    }
  }

  function generateLayout(charts) {
    setLayout([]);
    const chartLayout = charts.map(chart => {
      return {
        x: chart.x ? chart.x : 0,
        y: chart.y ? chart.y : 0,
        w: chart.size.w,
        h: chart.size.h
      };
    });
    setLayout(chartLayout);
  }
  function generateDOM() {
    return _.map(charts, function(chart, i) {
      return (
        <div key={i} className={""} data-grid={layout[i]}>
          {chart && chart.chart.type !== "VALUE" ? (
            <Chart
              id={chart.chart.id.toString()}
              height="100%"
              dashboard={match.params.dashboardID}
            />
          ) : (
            <Box
              id={chart.chart.id}
              height="100%"
              fixedDecimalScale={true}
              dashboard={match.params.dashboardID}
            />
          )}
        </div>
      );
    });
  }

  const editDashboard = () => {
    history.push(`/admin/editdashboard/${match.params.dashboardID}`);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button color="info" onClick={editDashboard}>
          {translate(`${section}.edit`)}
        </Button>
      </div>
      {layout.length > 0 && (
        <ReactGridLayout
          onLayoutChange={onLayoutChange}
          className="layout"
          cols={12}
        >
          {generateDOM()}
        </ReactGridLayout>
      )}
    </div>
  );
}

CustomDashboard.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  translate: PropTypes.func,
  from: PropTypes.object,
  to: PropTypes.object
};

const mapStateToProps = state => ({
  from: state.filterDates.from,
  to: state.filterDates.to
});

export default connect(mapStateToProps)(translate(CustomDashboard));
