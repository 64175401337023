import englishMessages from "ra-language-english";

export default {
  ...englishMessages,
  menu: {
    dashboard: "Dashboard",
    createdashboard: "Create new Dashboard",
    edituser: "Edit User",
    projects: "Projects",
    createproject: "Create new Project",
    editproject: "Edit Project",
    users: "Users",
    createuser: "Create new User",
    upload: "Upload",
    editdashboard: "Edit Dashboard",
    charts: "Custom Charts",
    createchart: "Create new Chart",
    editchart: "Edit Chart"
  },
  users: {
    newuser: "Add new User",
    users: "Users",
    username: "Username",
    name: "Name",
    mail: "Mail",
    role: "Role",
    company: "Company",
    actions: "Actions"
  },
  general: {
    loading: "Loading",
    cancel: "Cancel",
    submit: "Submit"
  },
  user: {
    edituser: "Edit User",
    createuser: "Create new User",
    selectproject: "Select Project",
    selectrole: "Select Role",
    company: "Company",
    password: "Password",
    lastname: "Last Name",
    firstname: "First Name",
    mail: "Email",
    username: "Username",
    admin: "Admin",
    user: "User"
  },
  projects: {
    createproject: "Create new Project",
    projects: "Projects",
    title: "Title",
    nrcountries: "Nr. of Countries",
    actions: "Actions"
  },
  project: {
    editproject: "Edit Project",
    createproject: "Create new Project ",
    title: "Title",
    selectcountries: "Select Countries",
    selectcurrency: "Select Currency",
    dbschema: "DB Schema"
  },
  login: {
    login: "Log In",
    password: "Password",
    forgotpassword: "Forgot Password",
    mail: "Email",
    username: "Username"
  },
  dashboardbox: {
    createdashboard: "Create new Dashboard",
    title: "Title",
    successmessage: "Dashboard created successfuly",
    errormessage: "An error occurred, Please try again"
  },
  createdashboard: {
    listcharts: "List of charts"
  },
  draggablechart: {
    name: "Name",
    size: "Size"
  },
  dashboardcard: {
    name: "Name",
    size: "Size",
    selectsize: "Select Size"
  },
  navbar: {
    selectcountry: "Select Country",
    from: "From",
    to: "To",
    editprofile: "Edit Profile",
    logout: "Log Out",
    selectlanguage: "Select Language",
    english: "English",
    german: "German"
  },
  profile: {
    editprofile: "Edit Profile",
    username: "Username",
    email: "Email Address",
    firstname: "First Name",
    lastname: "Last Name"
  },
  customdashboard: {
    edit: "Edit Dashboard"
  },
  upload: {
    uploadedfile: "Uploaded File",
    dragndrop: "Drag 'n' drop some files here, or click to select files",
    dbschema: "Select DB Schema",
    column: "Select Column",
    analyze: "Analyze",
    error: "An error occured, please try again!",
    acceptedDocs: "Only csv file are allowed!",
    upload: "Upload",
    headerRow: "Header Row",
    delimiter: "Delimiter"
  },
  charts: {
    charts: "Custom Charts",
    title: "Title",
    createchart: "Create new Chart"
  },
  chart: {
    editchart: "Edit Chart",
    createchart: "Create new Chart",
    title: "Title",
    selecttype: "Select Chart Type",
    query: "Query",
    config: "Configuration"
  }
};
