import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { routerMiddleware, routerReducer } from "react-router-redux";
import { reducer as formReducer } from "redux-form";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";
import fetchRoutes from "./reducers/UserRoutesReducer";
import fetchProject from "./reducers/ProjectReducer";
import fetchFilterDates from "./reducers/FilterDatesReducer";
import fetchFilterCountry from "./reducers/FilterCountryReducer";
import {
  adminReducer,
  adminSaga,
  defaultI18nProvider,
  i18nReducer,
  formMiddleware,
  USER_LOGOUT
} from "react-admin";
import onFetchUserRoutes from "./sagas/UserRoutesSaga";
import onFetchProject from "./sagas/ProjectSaga";
import onFetchFilterDates from "./sagas/FilterDatesSaga";
import onFetchFilterCountry from "./sagas/FilterCountrySaga";

export default ({
  authProvider,
  dataProvider,
  i18nProvider = defaultI18nProvider,
  history,
  locale
}) => {
  const reducer = combineReducers({
    admin: adminReducer,
    i18n: i18nReducer(locale, i18nProvider(locale)),
    form: formReducer,
    router: routerReducer,
    routes: fetchRoutes,
    project: fetchProject,
    filterDates: fetchFilterDates,
    filterCountry: fetchFilterCountry
  });
  const resettableAppReducer = (state, action) =>
    reducer(action.type !== USER_LOGOUT ? state : undefined, action);

  const saga = function* rootSaga() {
    yield all(
      [
        adminSaga(dataProvider, authProvider, i18nProvider),
        onFetchUserRoutes,
        onFetchProject,
        onFetchFilterDates,
        onFetchFilterCountry
      ].map(fork)
    );
  };
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    resettableAppReducer,
    compose(
      applyMiddleware(
        sagaMiddleware,
        formMiddleware,
        routerMiddleware(history)
        // add your own middlewares here
      ),
      typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
      // add your own enhancers here
    )
  );
  sagaMiddleware.run(saga);

  return store;
};
