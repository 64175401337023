import React, { useEffect, useState } from "react";
import { translate } from "react-admin";
import PropTypes from "prop-types";

import request from "../../api/apiRequests";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody.js";
import CardFooter from "../Card/CardFooter.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import DrawColumnLineChart from "./DrawColumnLineChart";
import DrawParetoChart from "./DrawParetoChart";
import DrawBubbleChart from "./DrawBubbleChart";
import DrawHeatMapChart from "./DrawHeatMapChart";
import { connect } from "react-redux";

const useStyles = makeStyles(styles);

function Chart({ id, height, width, from, to, country, translate, dashboard }) {
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const drawChart = async (dashboard, from, to, country, id) => {
    let countryParam = country === "" ? "" : `/${country}`;
    const response = await request(
      `charts/draw-chart/${dashboard}/${id}/${from.format(
        "YYYY-MM-DD"
      )}/${to.format("YYYY-MM-DD")}${countryParam}`,
      "GET"
    );
    setIsLoading(false);
    setTitle(response.data.title);
    if (response.data.success) {
      switch (response.data.type) {
        case "COLUMNLINE":
          DrawColumnLineChart(response.data);
          break;
        case "PARETO":
          DrawParetoChart(response.data);
          break;
        case "BUBBLE":
          DrawBubbleChart(response.data);
          break;
        case "HEATMAP":
          DrawHeatMapChart(response.data);
          break;
        default:
        // code block
      }
    }
  };

  useEffect(() => {
    drawChart(dashboard, from, to, country, id);
  }, [dashboard, from, to, country, id]);

  return (
    <Card
      style={{
        borderRadius: 0,
        margin: "0 5px",
        width,
        height
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%"
        }}
      >
        <CardBody
          style={{
            padding: 0,
            display: "flex",
            height: "100%"
          }}
        >
          {isLoading ? (
            <div
              style={{
                margin: "0 auto",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                height
              }}
            >
              <CircularProgress style={{ color: "#00acc1" }} />
              <p>{translate(`general.loading`) + "..."}</p>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                height: height - 50
              }}
              id={id.toString()}
            ></div>
          )}
        </CardBody>
        {!isLoading && (
          <CardFooter stats style={{ marginTop: 0 }}>
            <div className={classes.stats}>{title}</div>
          </CardFooter>
        )}
      </div>
    </Card>
  );
}

Chart.propTypes = {
  id: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.string,
  from: PropTypes.object,
  to: PropTypes.object,
  country: PropTypes.string,
  translate: PropTypes.func,
  dashboard: PropTypes.string
};

const mapStateToProps = state => ({
  from: state.filterDates.from,
  to: state.filterDates.to,
  country: state.filterCountry
});

export default connect(mapStateToProps)(translate(Chart));
