import React, { useState, useEffect } from "react";
import { translate } from "react-admin";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import { useDrop } from "react-dnd";
import DashboardCard from "views/Charts/DashboardCard";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import request from "../../api/apiRequests";
import CustomInput from "components/CustomInput/CustomInput.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbars from "components/Snackbar/Snackbar.js";
import setRoutes from "../../actions/UserRoutesAction";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";

const section = "dashboardbox";

const useStyles = makeStyles(styles);

const mapStateToProps = state => ({
  routesAPI: state.routes
});

const useSelectStyle = makeStyles(() => ({
  inputLabel: {
    fontSize: 12,
    top: 8,
    textTransform: "uppercase"
  },
  success: {
    border: 0
  },
  error: {
    borderBottom: "1px solid red",
    "&:before": {
      border: "none"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  }
}));

function DashboardBox({
  onDrop,
  dashboardCharts,
  routesAPI,
  setRoutes,
  history,
  moveCard,
  removeCard,
  translate,
  dashboardID,
  title,
  submitFlag,
  submitFlagHandler,
  removedCharts
}) {
  const classes = useStyles();
  const [sizes, setSizes] = useState([]);
  const [notificationType, setNotificationType] = useState("info");
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const [validTitle, setValidTitle] = useState(true);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [userID, setUserID] = useState(null);
  const [dashboardTitle, setDashboardTitle] = useState(title);
  const selectClasses = useSelectStyle();
  const [emptyChartsMessage, setEmptyChartsMessage] = useState("");
  const [apiMessage, setAPIMessage] = useState("");

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "CARD",
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  useEffect(() => {
    setDashboardTitle(title);
  }, [title]);

  // useEffect(() => {
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     // to stop the warning of calling setTl of unmounted component
  //     var id = window.setTimeout(null, 0);
  //     while (id--) {
  //       window.clearTimeout(id);
  //     }
  //   };
  // });

  const getSizes = async () => {
    const response = await request("sizes", "GET");
    setSizes(response.data);
  };

  const updateChart = async (chart, chartSize) => {
    return await request("chart-sizes", "POST", chartSize);
  };

  const saveDashboard = async data => {
    const response = await request(
      dashboardID ? `dashboards/${dashboardID}` : "dashboards",
      dashboardID ? "PUT" : "POST",
      data
    );
    setAPIMessage(response.message);
    setNotificationType(response.type ? "success" : "danger");
    if (response.type) {
      setUpdateFlag(true);
      dashboardCharts.forEach(chart => {
        if (chart.new) {
          const chartSize = {
            size: "/api/sizes/17",
            dashboard: `/api/dashboards/${response.data.id}`,
            chart: `/api/charts/${chart.id}`,
            x: 0,
            y: 0
          };
          updateChart(chart, chartSize);
        }
      });
      setUpdateFlag(false);
      updateRoutes(
        response.data["@id"],
        response.data["@type"],
        response.data.charts,
        response.data.id,
        response.data.predefined,
        response.data.project,
        response.data.title
      );
      setShowNotificationMessage(true);
    } else {
      setTimeout(() => close(response.type, null), 3000);
    }
  };

  const updateRoutes = (
    id,
    type,
    charts,
    dashboardID,
    predefined,
    project,
    title
  ) => {
    const navigation = {
      "@id": id,
      "@type": type,
      charts,
      id: dashboardID,
      predefined,
      project,
      title
    };
    routesAPI.push(navigation);
    setRoutes(routesAPI);
    setTimeout(() => close(type, dashboardID), 3000);
  };

  const close = (type, id) => {
    setShowNotificationMessage(false);
    if (type)
      return !updateFlag && id !== null
        ? history.push(`/admin/custom/${id}`)
        : "";
  };

  useEffect(() => {
    const TOKEN = localStorage.getItem("token");
    const decodedToken = jwt.decode(TOKEN);
    setUserID(decodedToken.id);
    getSizes();
  }, []);

  const verifyLength = (value, length) => {
    if (value.length > length) {
      return true;
    }
    return false;
  };

  const validateFields = () => {
    setValidTitle(verifyLength(dashboardTitle, 0) ? true : false);
    if (verifyLength(dashboardCharts, 0) && verifyLength(dashboardTitle, 0)) {
      return true;
    }
    if (!verifyLength(dashboardCharts, 0)) {
      setEmptyChartsMessage("No chart added");
    }
    return false;
  };

  const isActive = canDrop && isOver;
  let backgroundColor = "#fff";
  if (isActive) {
    backgroundColor = "#CCEBA8";
  } else if (canDrop) {
    backgroundColor = "#fff";
  }
  const deleteCharts = charts => {
    charts.forEach(async chart => {
      await request(`chart-sizes/${chart.id}`, "DELETE");
    });
  };
  const submitDashboard = () => {
    const validate = validateFields();
    if (validate) {
      submitFlagHandler(true);
      const data = {
        predefined: 0,
        title: dashboardTitle,
        users: [`/api/users/${userID}`],
        project: "/api/projects/1"
      };
      saveDashboard(data);
      if (removedCharts.length > 0) deleteCharts(removedCharts);
    }
  };
  return (
    <Card>
      <Snackbars
        place="tl"
        color={notificationType}
        icon={AddAlert}
        message={apiMessage}
        open={showNotificationMessage}
        closeNotification={() => setShowNotificationMessage(false)}
        close
      />
      <form>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <DashboardIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            {translate(`${section}.createdashboard`)}
          </h4>
        </CardHeader>
        <CardBody>
          <CustomInput
            error={!validTitle}
            labelText={translate(`${section}.title`) + " *"}
            id="title"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              className: validTitle
                ? selectClasses.success
                : selectClasses.error,
              onChange: event => {
                if (verifyLength(event.target.value, 0)) {
                  setValidTitle(true);
                } else {
                  setValidTitle(false);
                }
                setDashboardTitle(event.target.value);
              },
              value: dashboardTitle
            }}
          />
          <div
            ref={drop}
            style={{
              backgroundColor,
              height: 500,
              overflowY: "scroll"
            }}
          >
            {dashboardCharts.length > 0 &&
              dashboardCharts.map((card, i) => (
                <DashboardCard
                  key={i}
                  index={i}
                  card={card}
                  moveCard={moveCard}
                  removeCard={removeCard}
                  sizes={sizes}
                  dashboardID={dashboardID}
                />
              ))}
            {dashboardCharts.length === 0 && (
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                  color: "red"
                }}
              >
                {emptyChartsMessage}
              </p>
            )}
          </div>
        </CardBody>
        <CardFooter>
          <Button
            color="info"
            simple
            size="lg"
            block
            onClick={submitDashboard}
            disabled={submitFlag}
          >
            {translate(`general.submit`)}
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
}

DashboardBox.propTypes = {
  onDrop: PropTypes.func,
  routesAPI: PropTypes.array,
  setRoutes: PropTypes.func,
  history: PropTypes.object,
  dashboardCharts: PropTypes.array,
  removeCard: PropTypes.func,
  moveCard: PropTypes.func,
  translate: PropTypes.func,
  title: PropTypes.string,
  dashboardID: PropTypes.string,
  submitFlag: PropTypes.bool,
  submitFlagHandler: PropTypes.func,
  removedCharts: PropTypes.array
};

export default connect(
  mapStateToProps,
  {
    setRoutes
  }
)(translate(DashboardBox));
