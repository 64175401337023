import React, { useState, useEffect } from "react";
import { translate } from "react-admin";
import request from "../../api/apiRequests";
import PropTypes from "prop-types";
import _ from "lodash";

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import BarChartIcon from "@material-ui/icons/BarChart";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbars from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import ListSubheader from "@material-ui/core/ListSubheader";
import TextField from "@material-ui/core/TextField";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import chartFormStyles from "../../assets/jss/ChartPage";

const section = "chart";

const useStyles = makeStyles(styles);
const useTableStyles = makeStyles(tableStyles);
const chartForm = makeStyles(chartFormStyles);

const useSelectStyle = makeStyles(() => ({
  dropdown: {
    marginTop: "23px !important",
    "&:before": {
      borderBottom: "1px solid #D2D2D2"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #D2D2D2"
    },
    "&:after": {
      borderBottom: "2px solid transparent"
    }
  },
  dropdown1: {
    "&:before": {
      borderBottom: "1px solid #D2D2D2"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #D2D2D2"
    },
    "&:after": {
      borderBottom: "2px solid transparent "
    }
  },
  selectLabel: {
    fontSize: 12,
    top: 15,
    textTransform: "uppercase"
  },
  inputLabelValue: {
    margin: 0,
    padding: 0
  },
  success: {
    border: 0
  },
  error: {
    borderBottom: "1px solid red",
    "&:before": {
      border: "none"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  },
  errorTextArea: {
    borderBottom: "1px solid red",
    "& label": {
      color: "red !important"
    },
    "&:before": {
      borderBottom: "none !important"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none !important"
    }
  }
}));

const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid #D2D2D2"
    },
    "& .MuiFormLabel-root": {
      color: "#AAAAAA"
    },
    "& .Mui-error": {
      color: "red"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #D2D2D2"
    },
    "& .MuiInput-underline:after": {
      borderBottom: "2px solid #9c27b0"
    },
    "& .Mui-focused": {
      borderBottom: "none"
    }
  }
})(TextField);

function Chart({ translate, match, history }) {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const selectClasses = useSelectStyle();
  const chartFormClasses = chartForm();
  const [title, setTitle] = useState("");
  const [query, setQuery] = useState("");
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [validTitle, setValidTitle] = useState(true);
  const [validType, setValidType] = useState(true);
  const [validQuery, setValidQuery] = useState(true);
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const [notificationType, setNotificationType] = useState("info");
  const [apiMessage, setAPIMessage] = useState("");
  const [configExample, setConfigExample] = useState({});
  const [config, setConfig] = useState({
    x: "",
    y1: "",
    y2: "",
    y3: "",
    y4: ""
  });
  const [submitFlag, setSubmitFlag] = useState(false);

  const verifyLength = (value, length) => {
    if (value.length > length) {
      return true;
    }
    return false;
  };

  const verifyString = value => {
    var stringRex = new RegExp("^[a-zA-Z_]+$");
    if (stringRex.test(value)) {
      return true;
    }
    return false;
  };

  const handleSelectType = event => {
    setType(event.target.value);
    setValidType(true);
    if (event.target.value !== undefined) {
      switch (event.target.value.split("_")[1]) {
        case "1":
          setConfigExample({
            value: "VALUE 1"
          });
          setConfig({
            value: ""
          });
          break;
        case "2":
          setConfigExample({
            x: "VALUE 1",
            y1: "VALUE 2"
          });
          setConfig({
            x: "",
            y1: ""
          });
          break;
        case "3":
          setConfigExample({
            x: "VALUE 1",
            y1: "VALUE 2",
            y2: "VALUE 3"
          });
          setConfig({
            x: "",
            y1: "",
            y2: ""
          });
          break;
        case "4":
          setConfigExample({
            x: "VALUE 1",
            y1: "VALUE 2",
            y2: "VALUE 3",
            y3: "VALUE 4"
          });
          setConfig({
            x: "",
            y1: "",
            y2: "",
            y3: ""
          });
          break;
        case "5":
          setConfigExample({
            x: "VALUE 1",
            y1: "VALUE 2",
            y2: "VALUE 3",
            y3: "VALUE 4",
            y4: "VALUE 5"
          });
          setConfig({
            x: "",
            y1: "",
            y2: "",
            y3: "",
            y4: ""
          });
          break;
        default:
        // code block
      }
    }
  };

  const validateFields = () => {
    setValidTitle(verifyLength(title, 0) ? true : false);
    setValidQuery(verifyLength(query, 0) ? true : false);
    setValidType(verifyLength(type, 0) ? true : false);
    const validity = Object.keys(config).map(key => {
      return verifyString(config[key]);
    });
    if (
      verifyLength(title, 0) &&
      verifyLength(query, 0) &&
      verifyLength(type, 0) &&
      !_.includes(validity, false)
    ) {
      return true;
    }
    return false;
  };

  const updateChart = async (id, chart) => {
    const response = await request(`charts/${id}`, "PUT", chart);
    setAPIMessage(response.message);
    setShowNotificationMessage(true);
    setNotificationType(response.type ? "success" : "danger");
    setTimeout(() => close(response.type), 3000);
  };

  const createChart = async chart => {
    const response = await request("charts", "POST", chart);
    setAPIMessage(response.message);
    setShowNotificationMessage(true);
    setNotificationType(response.type ? "success" : "danger");
    setTimeout(() => close(response.type), 3000);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const validate = validateFields();
    if (validate) {
      setSubmitFlag(true);
      let chartSizes = ["/api/chart-sizes/1"];
      const body = {
        title,
        type: type.split("_")[0],
        chartSizes,
        query,
        configuration: `${JSON.stringify(config)}`,
        project: "/api/projects/1"
      };
      match.params.id ? updateChart(match.params.id, body) : createChart(body);
    }
  };

  const close = type => {
    setAPIMessage("");
    setShowNotificationMessage(false);
    setNotificationType("info");
    setSubmitFlag(false);
    if (type) history.push("/admin/charts");
  };

  const getChart = async id => {
    const chart = await request(`charts/${id}`, "GET");
    const values = Object.keys(JSON.parse(chart.data.configuration)).length;
    setTitle(chart.data.title);
    setType(`${chart.data.type}_${values}`);
    setQuery(chart.data.query);
    setConfig(JSON.parse(chart.data.configuration));
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (match.params.id) {
      getChart(match.params.id);
    }
    setIsLoading(false);
  }, [match.params.id]);

  const handleChange = (e, config) => {
    const updateConfig = { ...config };
    updateConfig[e.target.id] = e.target.value;
    setConfig(updateConfig);
  };

  return (
    <GridContainer>
      <Snackbars
        place="tl"
        color={notificationType}
        icon={AddAlert}
        message={apiMessage}
        open={showNotificationMessage}
        closeNotification={() => setShowNotificationMessage(false)}
      />
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <BarChartIcon />
            </CardIcon>
            <h4 className={tableClasses.cardIconTitle}>
              {match.params.id
                ? translate(`${section}.editchart`)
                : translate(`${section}.createchart`)}
            </h4>
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  style={{ color: "#00acc1", width: 50, height: 50 }}
                />
                <p style={{ color: "#000" }}>
                  {translate(`general.loading`) + "..."}
                </p>
              </div>
            ) : (
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    error={!validTitle}
                    labelText={translate(`${section}.title`) + " *"}
                    id="title"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      className: validTitle
                        ? selectClasses.success
                        : selectClasses.error,
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setValidTitle(true);
                        } else {
                          setValidTitle(false);
                        }
                        setTitle(event.target.value);
                      },
                      value: title
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth required>
                    <InputLabel
                      htmlFor="select-type"
                      error={!validType}
                      required
                      className={selectClasses.selectLabel}
                    >
                      {translate(`${section}.selecttype`)}
                    </InputLabel>
                    <Select
                      fullWidth
                      value={type}
                      onChange={handleSelectType}
                      MenuProps={{
                        className: classes.selectMenu,
                        classes: { paper: classes.selectPaper }
                      }}
                      className={[
                        selectClasses.dropdown,
                        validType ? selectClasses.success : selectClasses.error
                      ].join(" ")}
                      classes={{ select: classes.select }}
                      inputProps={{
                        name: "type",
                        id: "select-type"
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        {translate(`${section}.selecttype`)}
                      </MenuItem>
                      <ListSubheader>1 Value</ListSubheader>
                      <MenuItem value={"VALUE_1"}>VALUE</MenuItem>
                      <ListSubheader>2 Values</ListSubheader>
                      <MenuItem value={"PARETO_2"}>PARETO</MenuItem>
                      <MenuItem value={"COLUMNLINE_2"}>COLUMNLINE</MenuItem>
                      <MenuItem value={"HEATMAP_2"}>HEATMAP</MenuItem>
                      <ListSubheader>3 Values</ListSubheader>
                      <MenuItem value={"PARETO_3"}>PARETO</MenuItem>
                      <MenuItem value={"COLUMNLINE_3"}>COLUMNLINE</MenuItem>
                      <MenuItem value={"HEATMAP_3"}>HEATMAP</MenuItem>
                      <ListSubheader>4 Values</ListSubheader>
                      <MenuItem value={"PARETO_4"}>PARETO</MenuItem>
                      <MenuItem value={"COLUMNLINE_4"}>COLUMNLINE</MenuItem>
                      <MenuItem value={"HEATMAP_4"}>HEATMAP</MenuItem>
                      <ListSubheader>5 Values</ListSubheader>
                      <MenuItem value={"PARETO_5"}>PARETO</MenuItem>
                      <MenuItem value={"COLUMNLINE_5"}>COLUMNLINE</MenuItem>
                      <MenuItem value={"HEATMAP_5"}>HEATMAP</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CssTextField
                    multiline
                    required
                    label={translate(`${section}.query`)}
                    id="query"
                    fullWidth
                    rows={4}
                    rowsMax={10}
                    InputLabelProps={{
                      error: !validQuery
                    }}
                    InputProps={{
                      className: !validQuery ? selectClasses.errorTextArea : ""
                    }}
                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setValidQuery(true);
                        } else {
                          setValidQuery(false);
                        }
                        setQuery(event.target.value);
                      }
                    }}
                    value={query}
                  />
                </GridItem>
                {type !== "" && (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={chartFormClasses.flex}
                  >
                    <GridItem xs={12} sm={12} md={6}>
                      {Object.keys(configExample).length > 0 && (
                        <pre>{JSON.stringify(configExample, null, 2)}</pre>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className={chartFormClasses.marginVertically}>
                        {Object.keys(config).length > 0 && (
                          <p
                            className={[
                              chartFormClasses.margin,
                              chartFormClasses.fontWeight
                            ].join(" ")}
                          >
                            {"{"}
                          </p>
                        )}
                        {Object.keys(config).map(key => {
                          return (
                            <div
                              key={key}
                              className={[
                                chartFormClasses.configInputsContainer,
                                chartFormClasses.flex
                              ].join(" ")}
                            >
                              <p
                                style={{
                                  width: key === "value" ? 40 : 20
                                }}
                                className={[
                                  chartFormClasses.configKey,
                                  chartFormClasses.flex,
                                  chartFormClasses.fontWeight
                                ].join(" ")}
                              >
                                {key}:{" "}
                              </p>
                              <CustomInput
                                error={verifyString(config[key])}
                                id={key}
                                inputProps={{
                                  className: [
                                    verifyString(config[key])
                                      ? selectClasses.success
                                      : selectClasses.error,
                                    selectClasses.dropdown1
                                  ].join(" "),
                                  placeholder: configExample[key],
                                  value: config[key],
                                  onChange: e => handleChange(e, config, key)
                                }}
                                formControlProps={{
                                  fullWidth: true,
                                  className: selectClasses.inputLabelValue
                                }}
                              />
                            </div>
                          );
                        })}
                        {Object.keys(config).length > 0 && (
                          <p
                            className={[
                              chartFormClasses.margin,
                              chartFormClasses.fontWeight
                            ].join(" ")}
                          >
                            {"}"}
                          </p>
                        )}
                      </div>
                    </GridItem>
                  </GridItem>
                )}
              </GridContainer>
            )}
            <Button
              color="info"
              className={classes.updateProfileButton}
              onClick={handleSubmit}
              disabled={submitFlag}
            >
              {translate(`general.submit`)}
            </Button>
            <Clearfix />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

Chart.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  translate: PropTypes.func
};

export default translate(Chart);
