import React from "react";
import { connect } from "react-redux";
import { userLogout, translate } from "react-admin";
import MenuItem from "@material-ui/core/MenuItem";
import classNames from "classnames";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const section = "navbar";

const useStyles = makeStyles(styles);

function LogoutButton({ userLogout, rtlActive, translate }) {
  const classes = useStyles();
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });
  return (
    <MenuItem onClick={userLogout} className={dropdownItem}>
      {rtlActive ? "الخروج" : translate(`${section}.logout`)}
    </MenuItem>
  );
}

LogoutButton.propTypes = {
  rtlActive: PropTypes.bool,
  userLogout: PropTypes.func,
  translate: PropTypes.func
};
classNames.propTypes = {
  rtlActive: PropTypes.bool
};

const redirectTo = "/login";
const myCustomUserLogout = () => userLogout(redirectTo);
export default connect(
  undefined,
  { userLogout: myCustomUserLogout }
)(translate(LogoutButton));
