import { put, takeLatest } from "redux-saga/effects";

export default function* onFetchFilterDates() {
  yield takeLatest("FETCH_DATES", function* fetchFilterDates(dates) {
    try {
      const payload = dates.dates;
      yield put({ type: "FILTERDATES", payload });
    } catch (e) {
      yield put({ type: "FETCH_DATES_FAILED", message: e.message });
      return;
    }
  });
}
