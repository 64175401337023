const chartFormStyles = {
  flex: {
    display: "flex"
  },
  marginVertically: {
    margin: "10px 0"
  },
  margin: {
    margin: 0
  },
  padding: {
    padding: 0
  },
  configInputsContainer: {
    paddingLeft: 20,
    margin: 0
  },
  configKey: {
    marginRight: 15,
    marginBottom: 0,
    justifyContent: "center",
    flexDirection: "column"
  },
  fontWeight: {
    fontWeight: 400
  },
  selectLabel: {
    fontSize: 12,
    top: 15,
    textTransform: "uppercase"
  }
};

export default chartFormStyles;
