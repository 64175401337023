import React, { useEffect, useState } from "react";
import { translate } from "react-admin";
import PropTypes from "prop-types";
import request from "../../api/apiRequests";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardIcon from "../Card/CardIcon.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import EuroIcon from "@material-ui/icons/Euro";
import UpdateIcon from "@material-ui/icons/Update";
import AssessmentIcon from "@material-ui/icons/Assessment";
import InfoIcon from "@material-ui/icons/Info";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import CircularProgress from "@material-ui/core/CircularProgress";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";

const useStyles = makeStyles(styles);

function Box({
  id,
  from,
  to,
  height,
  fixedDecimalScale,
  width,
  translate,
  country,
  currency,
  dashboard
}) {
  const [title, setTitle] = useState("");
  const [value, setValue] = useState(0);
  const [key, setKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(false);
  const [apiMessage, setAPIMessage] = useState("");

  const classes = useStyles();
  const drawChart = async (dashboard, id, from, to, country) => {
    let countryParam = country === "" ? "" : `/${country}`;
    const response = await request(
      `charts/draw-chart/${dashboard}/${id}/${from.format(
        "YYYY-MM-DD"
      )}/${to.format("YYYY-MM-DD")}${countryParam}`,
      "GET"
    );
    if (response.data.success) {
      setAPIMessage("");
      let key = "";
      let value = "";
      key = response.data.data ? Object.keys(response.data.data[0])[0] : key;
      value = key !== "" ? response.data.data[0][key] : "";
      const configuration = JSON.parse(response.data.config);
      setValue(value);
      setKey(configuration.x);
      setTitle(response.data.title);
      setIsLoading(false);
      response.data.data[0][key] !== null
        ? setFetchedData(true)
        : setFetchedData(false);
    } else {
      setAPIMessage(response.data.message);
      setFetchedData(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    drawChart(dashboard, id, from, to, country);
  }, [dashboard, from, to, country, id]);

  const BoxIcon = key => {
    switch (key) {
      case "TV_VISITS":
        return <LiveTvIcon style={{ margin: 0 }} />;
      case "TOTAL_SPEND":
        return <LiveTvIcon style={{ margin: 0 }} />;
      case "REVENUE_SHARE_PER_REACH":
        return <EuroIcon style={{ margin: 0 }} />;
      case "CPO":
        return <UpdateIcon style={{ margin: 0 }} />;
      case "CPV":
        return <AssessmentIcon style={{ margin: 0 }} />;
      case "CPM":
        return <EuroIcon style={{ margin: 0 }} />;
      case "ROI":
        return <EuroIcon style={{ margin: 0 }} />;
      case "REVENUE_TV":
        return <EuroIcon style={{ margin: 0 }} />;
      case "AVG_ORDER_VALUE":
        return <InfoIcon style={{ margin: 0 }} />;
      default:
      // code block
    }
  };

  return (
    <Card
      style={{
        borderRadius: 0,
        margin: "0 5px",
        height,
        width
      }}
    >
      <CardHeader
        color="info"
        stats
        icon
        style={{ margin: "5%", height: "100%" }}
      >
        {isLoading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <CircularProgress
              style={{ color: "#00acc1", width: "auto", height: "auto" }}
            />
            <p style={{ color: "#000" }}>
              {translate(`general.loading`) + "..."}
            </p>
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              flex: 1
            }}
          >
            {key !== "" && (
              <div
                style={{
                  marginBottom: "5%",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  flex: 1
                }}
              >
                <CardIcon
                  style={{
                    borderRadius: 0,
                    width: "45%",
                    padding: "5%",
                    marginRight: 0,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  color="info"
                >
                  {BoxIcon(key)}
                </CardIcon>
                <p
                  style={{ textAlign: "center", paddingTop: "5%" }}
                  className={classes.cardCategory}
                >
                  {title}
                </p>
              </div>
            )}
            <h3
              className={classes.cardTitle}
              style={{
                flexDirection: "column",
                display: "flex",
                flex: 3,
                textAlign: "center",
                justifyContent: "center"
              }}
            >
              {fetchedData && apiMessage === "" ? (
                <NumberFormat
                  value={value}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={currency === "EURO" ? "€" : "$"}
                  fixedDecimalScale={fixedDecimalScale}
                  decimalScale={2}
                  defaultValue={0}
                  isNumericString={true}
                />
              ) : (
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "80%",
                    margin: 0
                  }}
                >
                  {apiMessage !== "" ? apiMessage : "No Data Found"}
                </p>
              )}
            </h3>
          </div>
        )}
      </CardHeader>
    </Card>
  );
}

Box.propTypes = {
  id: PropTypes.number,
  from: PropTypes.object,
  to: PropTypes.object,
  height: PropTypes.string,
  width: PropTypes.string,
  translate: PropTypes.func,
  country: PropTypes.string,
  fixedDecimalScale: PropTypes.bool,
  currency: PropTypes.string,
  dashboard: PropTypes.string
};

const mapStateToProps = state => ({
  currency: state.project.currency,
  from: state.filterDates.from,
  to: state.filterDates.to,
  country: state.filterCountry
});

export default connect(mapStateToProps)(translate(Box));
