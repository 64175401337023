import React, { useState, useEffect } from "react";
import { translate } from "react-admin";
import request from "../../api/apiRequests";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import PermIdentity from "@material-ui/icons/PermIdentity";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbars from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const section = "project";

const useStyles = makeStyles(styles);
const useTableStyles = makeStyles(tableStyles);

const useSelectStyle = makeStyles(() => ({
  dropdown: {
    "&:before": {
      borderBottom: "1px solid #D2D2D2"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #D2D2D2"
    },
    "&:after": {
      borderBottom: "2px solid transparent"
    }
  },
  inputLabel: {
    fontSize: 12,
    top: 8,
    textTransform: "uppercase"
  },
  success: {
    border: 0
  },
  error: {
    borderBottom: "1px solid red",
    "&:before": {
      border: "none"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  }
}));

function Project({ translate, match, history }) {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const selectClasses = useSelectStyle();
  const [title, setTitle] = useState("");
  const [dbSchema, setDBSchema] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [currency, setCurrency] = useState("");
  const [externalAccountId, setExternalAccountId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [validTitle, setValidTitle] = useState(true);
  const [validCountries, setValidCountries] = useState(true);
  const [validCurrency, setValidCurrency] = useState(true);
  const [validDBSchema, setValidDBSchema] = useState(true);
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const [notificationType, setNotificationType] = useState("info");
  const [apiMessage, setAPIMessage] = useState("");

  const handleSelectCurrency = event => {
    setCurrency(event.target.value);
  };

  const verifyLength = (value, length) => {
    if (value.length > length) {
      return true;
    }
    return false;
  };

  const handleSelectCountries = event => {
    setSelectedCountries(event.target.value);
  };

  const validateFields = () => {
    setValidTitle(verifyLength(title, 0) ? true : false);
    setValidCountries(verifyLength(selectedCountries, 0) ? true : false);
    setValidCurrency(verifyLength(currency, 0) ? true : false);
    setValidDBSchema(verifyLength(dbSchema, 0) ? true : false);
    if (
      verifyLength(title, 0) &&
      verifyLength(selectedCountries, 0) &&
      verifyLength(currency, 0) &&
      verifyLength(dbSchema, 0)
    ) {
      return true;
    }
    return false;
  };

  const updateProject = async (id, project) => {
    const response = await request(`projects/${id}`, "PUT", project);
    setAPIMessage(response.message);
    setShowNotificationMessage(true);
    setNotificationType(response.type ? "success" : "danger");
    setTimeout(() => close(response.type), 3000);
  };

  const createProject = async project => {
    const response = await request("projects", "POST", project);
    setAPIMessage(response.message);
    setShowNotificationMessage(true);
    setNotificationType(response.type ? "success" : "danger");
    setTimeout(() => close(response.type), 3000);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const validate = validateFields();
    if (validate) {
      if (match.params.id) {
        const countries = selectedCountries.map(country => {
          return `api/countries/${country}`;
        });
        const body = {
          title,
          countries,
          currency,
          externalAccountId,
          dbSchema
        };

        updateProject(match.params.id, body);
      } else {
        const countries = selectedCountries.map(country => {
          return `api/countries/${country}`;
        });
        const body = {
          title,
          externalAccountId: Math.floor(Math.random() * 10),
          countries,
          currency,
          dbSchema
        };
        createProject(body);
      }
    }
  };

  const close = type => {
    setAPIMessage("");
    setShowNotificationMessage(false);
    setNotificationType("info");
    if (type) history.push("/admin/projects");
  };

  const getProject = async id => {
    const project = await request(`projects/${id}`, "GET");
    const countries = project.data.countries.map(country => {
      return country.id;
    });
    setDBSchema(project.data.dbSchema);
    setTitle(project.data.title);
    setSelectedCountries(countries);
    setCurrency(project.data.currency);
    setExternalAccountId(project.data.externalAccountId);
    setIsLoading(false);
  };

  const getCountries = async () => {
    const response = await request("countries?pagination=false", "GET");
    const countries = response.data.map(country => {
      return {
        name: country.nameShortEn,
        id: country.id
      };
    });
    setCountries(countries);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (match.params.id) {
      getProject(match.params.id);
    }
    getCountries();
  }, [match.params.id]);

  return (
    <GridContainer>
      <Snackbars
        place="tl"
        color={notificationType}
        icon={AddAlert}
        message={apiMessage}
        open={showNotificationMessage}
        closeNotification={() => setShowNotificationMessage(false)}
      />
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <PermIdentity />
            </CardIcon>
            <h4 className={tableClasses.cardIconTitle}>
              {match.params.id
                ? translate(`${section}.editproject`)
                : translate(`${section}.createproject`)}
            </h4>
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  style={{ color: "#00acc1", width: 50, height: 50 }}
                />
                <p style={{ color: "#000" }}>
                  {translate(`general.loading`) + "..."}
                </p>
              </div>
            ) : (
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    error={!validTitle}
                    labelText={translate(`${section}.title`) + " *"}
                    id="title"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      className: validTitle
                        ? selectClasses.success
                        : selectClasses.error,
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setValidTitle(true);
                        } else {
                          setValidTitle(false);
                        }
                        setTitle(event.target.value);
                      },
                      value: title
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    error={!validTitle}
                    labelText={translate(`${section}.dbschema`) + " *"}
                    id="dbschema"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      className: validDBSchema
                        ? selectClasses.success
                        : selectClasses.error,
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setValidDBSchema(true);
                        } else {
                          setValidDBSchema(false);
                        }
                        setDBSchema(event.target.value);
                      },
                      value: dbSchema
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth required>
                    <InputLabel
                      htmlFor="select-countries"
                      error={!validCountries}
                      required
                      className={selectClasses.inputLabel}
                    >
                      {translate(`${section}.selectcountries`)}
                    </InputLabel>
                    <Select
                      fullWidth
                      multiple
                      value={selectedCountries}
                      onChange={handleSelectCountries}
                      MenuProps={{
                        className: classes.selectMenu,
                        classes: { paper: classes.selectPaper }
                      }}
                      className={[
                        selectClasses.dropdown,
                        validCountries
                          ? selectClasses.success
                          : selectClasses.error
                      ].join(" ")}
                      classes={{ select: classes.select }}
                      inputProps={{
                        name: "countries",
                        id: "select-countries"
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        {translate(`${section}.selectcountries`)}
                      </MenuItem>
                      {countries.length > 0 &&
                        countries.map(country => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelectedMultiple
                              }}
                              value={country.id}
                              key={country.id}
                            >
                              {country.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth required>
                    <InputLabel
                      htmlFor="select-currency"
                      error={!validCurrency}
                      required
                      className={selectClasses.inputLabel}
                    >
                      {translate(`${section}.selectcurrency`)}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      className={[
                        selectClasses.dropdown,
                        validCurrency
                          ? selectClasses.success
                          : selectClasses.error
                      ].join(" ")}
                      value={currency}
                      onChange={handleSelectCurrency}
                      inputProps={{
                        name: "currency",
                        id: "select-currency"
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        {translate(`${section}.selectcurrency`)}
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={"EURO"}
                      >
                        €
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={"DOLLAR"}
                      >
                        $
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            )}
            <Button
              color="info"
              className={classes.updateProfileButton}
              onClick={handleSubmit}
            >
              {translate(`general.submit`)}
            </Button>
            <Clearfix />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

Project.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  translate: PropTypes.func
};

export default translate(Project);
