import { put, takeLatest } from "redux-saga/effects";

export default function* onFetchFilterCountry() {
  yield takeLatest("FETCH_COUNTRY", function* fetchFilterCountry(country) {
    try {
      const payload = country.country;
      yield put({ type: "FILTERCOUNTRY", payload });
    } catch (e) {
      yield put({ type: "FETCH_COUNTRY_FAILED", message: e.message });
      return;
    }
  });
}
