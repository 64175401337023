import React, { useState, useEffect } from "react";
import { translate } from "react-admin";
import PropTypes from "prop-types";
import request from "../../api/apiRequests";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import PermIdentity from "@material-ui/icons/PermIdentity";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbars from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const section = "user";

const useStyles = makeStyles(styles);
const useTableStyles = makeStyles(tableStyles);

const useSelectStyle = makeStyles(() => ({
  dropdown: {
    "&:before": {
      borderBottom: "1px solid #D2D2D2"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #D2D2D2"
    },
    "&:after": {
      borderBottom: "2px solid transparent"
    }
  },
  inputLabel: {
    fontSize: 12,
    top: 8,
    textTransform: "uppercase"
  },
  success: {
    border: 0
  },
  error: {
    borderBottom: "1px solid red",
    "&:before": {
      border: "none"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  }
}));

const initialUser = {
  username: "",
  mail: "",
  firstName: "",
  lastName: "",
  password: "",
  company: ""
};

function User({ match, translate, history }) {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const selectClasses = useSelectStyle();
  const [data, setData] = useState(initialUser);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [roleOptions] = useState([
    {
      role: "ROLE_ADMIN",
      name: translate(`${section}.admin`)
    },
    {
      role: "ROLE_USER",
      name: translate(`${section}.user`)
    }
  ]);
  const [project, setProject] = useState("");
  const [role, setRole] = useState("");
  const [validUsername, setValidUsername] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [validRole, setValidRole] = useState(true);
  const [validProject, setValidProject] = useState(true);
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const [notificationType, setNotificationType] = useState("info");
  const [apiMessage, setAPIMessage] = useState("");

  const handleSelect = event => {
    if (event.target.name === "role") setRole(event.target.value);
    if (event.target.name === "project")
      setProject(event.target.value.toString());
  };
  const handleChange = event => {
    data[event.target.id] = event.target.value;
  };
  const verifyLength = (value, length) => {
    if (value !== undefined && value.length > length) {
      return true;
    }
    return false;
  };

  const verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  const validateFields = () => {
    setValidUsername(verifyLength(data.username, 0) ? true : false);
    setValidEmail(verifyEmail(data.mail) ? true : false);
    setValidPassword(verifyLength(data.password, 0) ? true : false);
    setValidRole(verifyLength(role, 0) ? true : false);
    setValidProject(verifyLength(project, 0) ? true : false);
    if (!match.params.id) {
      if (
        verifyLength(data.username, 0) &&
        verifyEmail(data.mail) &&
        verifyLength(data.password, 0) &&
        verifyLength(role, 0) &&
        verifyLength(project, 0)
      ) {
        return true;
      }
    } else {
      if (
        verifyLength(data.username, 0) &&
        verifyEmail(data.mail) &&
        verifyLength(role, 0) &&
        verifyLength(project, 0)
      ) {
        return true;
      }
    }
    return false;
  };

  const handleSubmit = event => {
    event.preventDefault();
    const validate = validateFields();
    if (validate) {
      if (match.params.id) {
        const user = {
          username: data.username,
          mail: data.mail,
          firstName: data.firstName,
          lastName: data.lastName,
          company: data.company,
          role,
          projects: [`/api/projects/${parseInt(project)}`]
        };
        updateUser(match.params.id, user);
      } else {
        data.role = role;
        data.projects = [`/api/projects/${project}`];
        createUser(data);
      }
    }
  };

  const close = type => {
    setAPIMessage("");
    setShowNotificationMessage(false);
    setNotificationType("info");
    if (type) history.push("/admin/users");
  };

  const updateUser = async (id, user) => {
    const response = await request(`users/${id}`, "PUT", user);
    setAPIMessage(response.message);
    setShowNotificationMessage(true);
    setNotificationType(response.type ? "success" : "danger");
    setTimeout(() => close(response.type), 3000);
  };

  const createUser = async user => {
    const response = await request(`users`, "POST", user);
    setAPIMessage(response.message);
    setShowNotificationMessage(true);
    setNotificationType(response.type ? "success" : "danger");
    setTimeout(() => close(response.type), 3000);
  };

  const getProjects = async () => {
    let projects = await request("projects", "GET");
    projects = projects.data.map(project => {
      return {
        name: project.title,
        id: project.id
      };
    });
    setProjects(projects);
  };

  useEffect(() => {
    setIsLoading(true);
    const getUser = async () => {
      const user = await request(`users/${match.params.id}`, "GET");
      setData(user.data);
      setRole(user.data.roles[0]);
      setProject(user.data.projects[0].id.toString());
      setIsLoading(false);
    };
    if (match.params.id) {
      getUser();
    } else {
      setData(initialUser);
      setIsLoading(false);
    }
    getProjects();
  }, [match.params.id]);

  return (
    <GridContainer>
      <Snackbars
        place="tl"
        color={notificationType}
        icon={AddAlert}
        message={apiMessage}
        open={showNotificationMessage}
        closeNotification={() => setShowNotificationMessage(false)}
      />
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <PermIdentity />
            </CardIcon>
            <h4 className={tableClasses.cardIconTitle}>
              {match.params.id
                ? translate(`${section}.edituser`)
                : translate(`${section}.createuser`)}
            </h4>
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  style={{ color: "#00acc1", width: 50, height: 50 }}
                />
                <p style={{ color: "#000" }}>
                  {translate(`general.loading`) + "..."}
                </p>
              </div>
            ) : (
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    error={!validUsername}
                    labelText={translate(`${section}.username`) + " *"}
                    id="username"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      className: validUsername
                        ? selectClasses.success
                        : selectClasses.error,
                      onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setValidUsername(true);
                        } else {
                          setValidUsername(false);
                        }
                        data[event.target.id] = event.target.value;
                      },
                      defaultValue: data.username ? data.username : ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    error={!validEmail}
                    labelText={translate(`${section}.mail`) + " *"}
                    id="mail"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      className: validEmail
                        ? selectClasses.success
                        : selectClasses.error,
                      onChange: event => {
                        if (verifyEmail(event.target.value)) {
                          setValidEmail("success");
                        } else {
                          setValidEmail("error");
                        }
                        data[event.target.id] = event.target.value;
                      },
                      defaultValue: data.mail ? data.mail : "",
                      type: "email"
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={translate(`${section}.firstname`)}
                    id="firstName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: data.firstName,
                      onChange: handleChange
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={translate(`${section}.lastname`)}
                    id="lastName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: data.lastName,
                      onChange: handleChange
                    }}
                  />
                </GridItem>
                {!match.params.id && (
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      error={!validPassword}
                      labelText={translate(`${section}.password`) + " *"}
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        className: validPassword
                          ? selectClasses.success
                          : selectClasses.error,
                        onChange: event => {
                          if (verifyLength(event.target.value, 0)) {
                            setValidPassword(true);
                          } else {
                            setValidPassword(false);
                          }
                          data[event.target.id] = event.target.value;
                        },
                        defaultValue: data.password ? data.password : "",
                        type: "password"
                      }}
                    />
                  </GridItem>
                )}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={translate(`${section}.company`)}
                    id="company"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: data.company,
                      onChange: handleChange
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth required>
                    <InputLabel
                      error={!validRole}
                      htmlFor="role"
                      className={selectClasses.inputLabel}
                    >
                      {translate(`${section}.selectrole`)}
                    </InputLabel>
                    {data && (
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        className={[
                          selectClasses.dropdown,
                          validRole
                            ? selectClasses.success
                            : selectClasses.error
                        ].join(" ")}
                        value={role}
                        onChange={handleSelect}
                        inputProps={{
                          name: "role",
                          id: "role"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          {translate(`${section}.selectrole`)}
                        </MenuItem>
                        {roleOptions.length > 0 &&
                          roleOptions.map((role, i) => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected:
                                    classes.selectMenuItemSelectedMultiple
                                }}
                                value={role.role}
                                key={i}
                              >
                                {role.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  {match.params.id ? (
                    <FormControl fullWidth disabled>
                      <InputLabel
                        error={!validProject}
                        htmlFor="project"
                        className={selectClasses.inputLabel}
                      >
                        {translate(`${section}.selectproject`)}
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        className={[
                          selectClasses.dropdown,
                          validProject
                            ? selectClasses.success
                            : selectClasses.error
                        ].join(" ")}
                        value={project}
                        onChange={handleSelect}
                        inputProps={{
                          name: "project",
                          id: "project"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          {translate(`${section}.selectproject`)}
                        </MenuItem>
                        {projects.length > 0 &&
                          projects.map(project => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected:
                                    classes.selectMenuItemSelectedMultiple
                                }}
                                value={project.id}
                                key={project.id}
                              >
                                {project.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl fullWidth required>
                      <InputLabel
                        error={!validProject}
                        htmlFor="project"
                        className={selectClasses.inputLabel}
                      >
                        {translate(`${section}.selectproject`)}
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        className={[
                          selectClasses.dropdown,
                          validProject
                            ? selectClasses.success
                            : selectClasses.error
                        ].join(" ")}
                        value={project}
                        onChange={handleSelect}
                        inputProps={{
                          name: "project",
                          id: "project"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          {translate(`${section}.selectproject`)}
                        </MenuItem>
                        {projects.length > 0 &&
                          projects.map(project => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected:
                                    classes.selectMenuItemSelectedMultiple
                                }}
                                value={project.id}
                                key={project.id}
                              >
                                {project.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  )}
                </GridItem>
              </GridContainer>
            )}
            <Button
              color="info"
              className={classes.updateProfileButton}
              onClick={handleSubmit}
            >
              {translate(`general.submit`)}
            </Button>
            <Clearfix />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

User.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  translate: PropTypes.func
};

export default translate(User);
